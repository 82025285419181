import React from 'react'
import '../innerpage.css'
import { Container, Col, Accordion } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import { Helmet } from 'react-helmet'

const ausStudy = () => {
    return (
        <>
            <Helmet>
                <title>Study In Australia: Top Universities, Scholarships, Cost & Visa| ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content='Looking to study in Australia? ExpertGRAD can help you find the top universities, scholarships, and help you with the cost and visa process. Get started today!' />
            </Helmet>
            <Container className='studybg my-5 text-center'>
                <h4>Study in Australia</h4>
                <p>When the Dutch navigator Willem Janszoon first landed in Australia in 1606. Little did he realise that a few centuries later this place, once inhabited by Aboriginal and Torres Strait Islander people, would soon become an education hub and be thronged by several students looking to study in Australia to make a promising career.</p>
            </Container>

            <Container className='mt-5'>
                <h4 className='innertitle'>How Does ExpertGRAD Helps You? </h4>
                <p>ExpertGRAD provides a gamut of services to students who desire to study abroad. As a one-stop service provider, ExpertGRAD provides support to students at each step. There are several stages, from deciding to study overseas to finally studying in a foreign land. For all those crucial steps where every student needs guidance, the ExpertGRAD professionals are here to help. The services we offer: </p>

                <ul class="secpnt ps-0">
                    <li><strong>Career Counselling:</strong> Helping students decide on a suitable course of study and finding a favourable university. </li>
                    <li><strong>Test Preparation:</strong> Our experienced experts offer personal assistance to students who want to clear the English language proficiency tests.
                    </li>
                    <li><strong>Admission Guidance:</strong> Getting into the dream university is tricky when students do it alone, but with the help of our experts, the SOP writing an admission interview process is a smooth ride.
                    </li>
                    <li><strong>Visa Services: </strong>Once the student has received the acceptance letter from their dream university, the next step is to get the visa. With our expert’s assistance, it becomes easier to get a student visa without missing out on any crucial document.
                    </li>
                    <li><strong>Forex Exchange: </strong> Before flying to Australia, students need to have adequate money in their pockets. As the currency exchange can be tough, ExpertGRAD provides that service under the same roof as any other consultation service.
                    </li>
                    <li><strong>Accommodation Services:</strong> Once a student has reached Australia and got admission to their dream university, the end question is about staying. With our local consultants available 24/7, we make it easier for students to find a place they can call home.
                    </li>
                </ul>
            </Container>

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Question </h3>
                    </Col>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: How much does it cost to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Depending upon the level of education and the university, the cost of education in Australia could vary between AUD 20,000 to 50,000 per year.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: Is Australia an expensive place to study?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: With its high fees and cost of living, Australia is the world's most expensive destination for international students.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: Are tuition fees high for international students in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Yes, the average tuition fee for international students is 3 times the fee paid by an Australian student. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: Which course is the cheapest in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The course which has the lowest fees in Australia is the Diploma of Leadership and Management. The tuition fee is AUD 8,000 for two years.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5: Which university is the cheapest in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Some of the most affordable universities in Australia are the University of Sunshine Coast, University of Queensland, Charles Darwin University, Griffith University, and Western Sydney University, among others.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6: Which course is best in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Business Management, Engineering, Hospitality Management, and Medical courses like Nursing & Pharmacy are the best courses in Australia.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7: Which course is best for a job in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The best courses to join in Australia are Management, Pharmacy, Nursing, Core Engineering,  </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q.8: Which degree is best for the future in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The top 5 degrees best for the future in Australia are Pharmacy, BBA, Biomedical Engineering, and Hospitality Management.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Q.9: How much money is required to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Depending upon the course, level of qualification chosen, and city, the cost of one student per year ranges between AUD 20,000 to 45,000 per year.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10">
                            <Accordion.Header>Q.10: What GPA do I need to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: To study in Australia, one must have a minimum GPS of 2.8 on a 4-point scale. </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="11">
                            <Accordion.Header>Q.11: What are the best medical courses to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The best medical courses to study in Australia are Pharmacy, Nursing, Medicine, and MBBS.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="12">
                            <Accordion.Header>Q.12: Which course is best for international students in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Management, Pharmacy, and Medicine are the best courses best for international students in Australia. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="13">
                            <Accordion.Header>Q.13: Is Australia cheap for studying?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Compared to the UK and USA, Australia is a cheaper destination for studying.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="14">
                            <Accordion.Header>Q.14: How can I go to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: To study in Australia, research about the university and the course you want to enroll in. Then apply online and submit the required fee and documents. Once you get approval from the university, you need to apply for a visa.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="15">
                            <Accordion.Header>Q.15: How much money do I need for an Australian student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The fee for getting a student visa for Australia is AUD 650.  </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="16">
                            <Accordion.Header>Q.16: What is the age limit to study in Australia for Higher Education?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The minimum age limit for studying in Australia is 18 years .</p>
                            </Accordion.Body>
                        </Accordion.Item>


                    </Accordion>
                </Container>
            </section>
        </>
    )
}

export default ausStudy