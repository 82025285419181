import '../innerpage.css'
import { Container, Tabs, Tab, Row, Col, Accordion } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
const UkVisaMain = () => {


    return (
        <>
            <Helmet>
                <title>Student Visa Guide for the UK: Cost, Requirements, Documents & Process | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content="Are you planning on studying in the UK? Then you'll need to get a student visa! Our guide covers everything you need to know about the student visa for the UK, including the cost, requirements, documents, and process. Get all the information you need right here." />
            </Helmet>

            
            <Container className='studybg my-5'>
                <h4>Student Visa Guide for UK</h4>
                <p>Dream of studying in the UK and getting a degree from Vilayat? ExpertGRAD can help you turn your dream into reality. So read along and get all your queries solved about student visas for the UK.</p>
            </Container>

            <Container className='innerTabs  mt-5 container'>
               
                <h5>All important information about UK student visas:</h5>
                <p>For someone, who is not a natural citizen of the UK, a student visa is a must before entering the UK to study. </p>

                <h5>Cost/Fees of student visa</h5>
                <p>There are 2 types of UK student visas. These are:</p>
                <ul className='ps-0'>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Short-term study visa</li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Tier 4 (General) student visa </li>
                </ul>
                <p>A short-term study visa is granted for courses with a duration of less than 6 months or 11 months in case of an English language course. You would require a Tier 4 (General) UK Student visa for longer-duration courses.
                </p>
                <p>The Visa application fee for a short-term visa is £363, while the fee for a Tier 4 (General) visa is £200.</p>
           
            </Container>


            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='UK Student Visa '>
                            <h4 className='innertitle'> Requirement</h4>
                            <p>
                            A person who is not a citizen of the UK can apply for a student visa. The primary requirements are completion of 16 years of age, confirmed admission from a recognized educational institution in the UK, proficiency in the English language, and enough money to pay for studying and living in the UK.
                            </p>
                        </Tab>

                        <Tab eventKey='tab-2' title='Documents Required for UK Student Visa'>
                            <h4 className='innertitle'>Documents Required for UK Student Visa</h4>
                            <p><strong>A person applying for a student visa to the UK needs to furnish the following documents:</strong></p>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>A visa application form, complete in all respects</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>
                                    A valid passport or other travel documentation approved by the UK government.

                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Proof of financial strength to support your living expenses for the course duration</li>

                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Confirmation of Acceptance for Studies (CAS) reference number and documents used to obtain CAS</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Colour Photographs (passport-size)
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> TB test results (mandatory for South Asian countries)
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Assessment documentation</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Academic Technology Approval Scheme (ATAS) clearance certificate (if required)</li>
                            </ul>
                        </Tab>

                        <Tab eventKey='tab-3' title='Eligibility Criteria for UK Student Visa'>
                            <h4 className='innertitle'>Eligibility Criteria for UK Student Visa</h4>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Person must be at least 16 years of age</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Person must have confirmed admission proof into a recognized UK institution </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> English Language proficiency test scores such as IELTS, TOEFL, or PTE</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Financial evidence to support studying and living expenses. You need a monthly maintenance fund of about £1,000- £1,500.
                                </li>
                            </ul>
                        </Tab>

                        <Tab eventKey='tab-5' title='UK Study Visa Process'>
                            <h4 className='innertitle'>UK Study Visa Process </h4>
                            <p><strong>The process of applying for a UK student visa is very simple. The various steps are:</strong></p>

                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Check your eligibility for a UK student visa. You need to have a CAS number, a valid passport, and financial strength proof as maintenance funds.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Get all your documents ready, create an account on the official UK visa website and apply online.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Use the online appointment calendar to book an appointment at the visa application centre.  </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Take a print of the application and sign it. </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Submit the application. You will receive an email containing your application number.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Pay the visa fee. You may pay online using a credit or debit card. You may also pay via bank DD or cash at the visa application centre.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Visit the visa application centre on the appointed date. Submit the application form, photographs and fingerprints.</li>
                            </ul>
                        </Tab>
                        <Tab eventKey='tab-6' title='Processing time'>
                            <h4 className='innertitle'>Processing time</h4>
                            <p>The UK visa application centre typically takes 2-3 weeks to process the visa application.
                            </p>
                        </Tab>

                        <Tab eventKey='tab-7' title='Visa with ExpertGRAD'>
                            <h4 className='innertitle'>Apply for a student visa with ExpertGRAD</h4>
                            <p>While applying for a UK visa may be cumbersome, ExpertGRAD can make the process smooth for you. The combined experience of our team helps you understand the various nuances involved in the visa process. </p>
                            <p>   Our team of experts provides you with correct and timely information regarding the various requirements to be filled out to get your visa approved.
                            </p>
                        </Tab>


                    </Tabs>
                </Row>
            </Container>


            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Question </h3>
                    </Col>


                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: What are the new rules for student visas in the UK?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Starting 1 January 2022, the UK government has enforced the 'new points-based system' Student route into force. This will remove the limit on the number of international students who can come to the UK. Individuals highly skilled in scientific, academic, or technology-related qualifications will get a priority in visa.
                                   
                                </p>
                                <p> The new rules will enable the students to work and live in the UK for 2 years after completing a Master's degree and 3 years after completing a PhD. In addition, the government can extend the PSW limit for Master's degree holders from 2 to 4 years.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Q.2: What is the 28 days rule in a UK student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The 28 days rule states that the money you require as maintenance funds must be in your account or your parent(s) account for a consecutive period of 28 days
                                    (finishing on the closing balance date).
                                </p>

                                <p>The end date of the 28 days must be within 31 days of the date you apply for your visa. So, for example, if you submit your application on 1 January 2022, you need to have the required amount in your bank account for at least 28 days ending 1 December 2021. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: How many years of visa does the UK give for students?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: If you're 18 or over and studying degree level, you get a visa for 5 years. For a course below degree level, you get a visa for 2 years.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: How much bank balance is required for a UK study visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: To study in the UK, you need a minimum of £1,334 per month (for up to 9 months) if studying in London and £1,023 per month (for up to 9 months) if studying outside London.</p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5: How much money can a student carry to the UK?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A student can carry an amount equal to $10,000 when entering the UK.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6: Does the UK visa office verify bank statements?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: If the visa office is not convinced with your financial papers, they may crosscheck with your bank to verify the financial statements.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7: Does the embassy check the bank statement?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The UK embassy wants to ensure your financial strength. That's why they ask for bank statements.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q.8: How many days can you stay outside the UK on a Student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: You can travel in and out of the UK at any time whilst your visa is valid. This includes the additional 'wrap up' period after completing the course, providing that you have completed the system within the stipulated time, and not earlier than that.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                       
                    </Accordion>
                </Container>
            </section>

        </>
    )
}

export default UkVisaMain