import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import './banner.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import arrowleft from '../../../images/arrow-left.png'
import banner from '../../../images/banner.webp'
import cap from '../../../images/cap.png'
import ga from '../../../images/trainedcounselors.png'
import tcn from '../../../images/getassestance.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Banner = () => {
   
    return (
        <>
            <Helmet>
                <title>Academic Success Assured: Helping you Excel your academic goals - ExpertGrad </title>
                <meta name='description' data-react-helmet="true" content="ExpertGrad's Academic Success program is a personalized study support program that helps students studying in Australia, USA, UK, Canada, India and other Global locations achieve their academic goals. We offer a variety of services, including tutoring, assignment assistance, and exam preparation." />
            </Helmet>
            <div className='banner pb-5 pt-4'>
                <Header></Header>
                <div className='container-fluid'>
                    <div id="cf3">
                        <Row className='bottom'>                            
                            <Col md={6} className='bt-pd-120'>
                                <div className='strip'>
                                    <span>New</span>Get a quick <Link to="/contact-us" style={{ color: '#3FA474' }}><strong>Free Expert Call</strong></Link>
                                </div>
                                <h1>Helping Students Build a Brighter Future</h1>
                                <p>Start Your Journey with our Eminent Academic Mentors </p>
                                {/* <button className="btn-yellow">
                                    <Link to={'/study-support/'} target='_blank'>Become a Partner</Link>
                                    <img src={arrowleft} alt="arrowleft" height={15}></img>
                                </button> */}

                            </Col>
                            <Col md={6}>
                                <img src={cap} alt="cap" className='p1 animated2 infinite fadeInUpDown'></img>
                                <img src={tcn} alt="Trained Mentors" className='p2 animated2 infinite fadeInLeftRight'></img>
                                <img src={banner} alt="banner" className='img-fluid'></img>
                                <img src={ga} alt="Assured success in Academics" className='p3 animated2 infinite fadeInLeftRight'></img>
                            </Col>
                        </Row>

                        {/* <Row className='top'>
                        <Col md={6} className='bt-pd-120'>
                            <div className='strip'>
                                <span>New</span>Get a quick <Link to="/contact" style={{color:'#3FA474'}}><strong>Free Assesment</strong></Link>
                            </div>
                            <h1>Give Wings To Your Dreams</h1>
                            <p>Start Your Journey with our Eminent Study Abroad Consultants </p>
                            <button className="btn-yellow">
                                <Link to="/contact">Book Your Seat</Link>
                                <img src={arrowleft} alt="arrowleft" height={15}></img>
                            </button>
                        </Col>
                        <Col md={6}>
                            <img src={cap} alt="cap" className='p1 animated2 infinite fadeInUpDown'></img>
                            <img src={tcn} alt="tcn" className='p2 animated2 infinite fadeInLeftRight'></img>
                            <img src={banner} alt="banner" className='img-fluid'></img>
                            <img src={ga} alt="ga" className='p3 animated2 infinite fadeInLeftRight'></img>

                        </Col>
                    </Row> */}
                    </div>
                </div>
            </div>

            {/* <div id="cf3" className="shadow">
  <img className="bottom" src={cap} alt="cap" />
  <img className="top" src={tcn} alt="2" />
</div> */}
        </>
    )
}

export default Banner