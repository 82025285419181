import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../innerpage.css'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import view from '../../../images/view.png'
import date from '../../../images/date.png'
import arrowright from '../../../images/arrow-right.png'

const Latestartical = () => {
    const [myBlogs, setMyBlogs] = useState([]);
    useEffect(() => {
        axios.get("//accountsfinanceassignmenthelp.com/api/blogs")
            .then((res) => setMyBlogs(res.data));
    }, []);
    return (
        <>
            <Container className="py-5 blog">
                <div className='d-flex justify-content-between align-items-center mb-4 blog-title'>
                    <h3>Latest Articles</h3>
                    {/* <input type="text" className='form-control'></input> */}
                </div>
                <Row>
                    {myBlogs.map((blogData) => {
                        const imgURL = 'https://accountsfinanceassignmenthelp.com/uploads/' + blogData.blogImg;
                        const blogDate = new Date(blogData.createdAt).toLocaleString('en-us',{month:'short', day:'numeric',year:'numeric'});
                        return (
                            <Col md={4} className="mt-4">
                                <Card>
                                    <Link to={`/blog/${blogData.slug}`}>
                                        <Card.Img variant="top" src={imgURL} />
                                    </Link>
                                    <Card.Body>
                                        {/* <div className='exp-info'>
                                            <div className='pic'>
                                                <img src={exp} alt="exp"></img>
                                            </div>
                                            <h6>Jessica jasmine 3434</h6>
                                        </div> */}
                                        <Card.Title> {blogData.blogTitle} </Card.Title>
                                        <strong> {blogData.shortDescription.substring(0, 40)} </strong>
                                        {/* <Card.Text>
                                            We all know that cheating is wrong, but do you know it could also lead you to jail
                                        </Card.Text> */}
                                        <div className='justify-content-between d-flex align-items-center'>
                                            <div className="d-flex mt-3">

                                                <div className="d-flex align-items-center">
                                                    <img src={view} alt="graduate" height={15}></img> <p className="ms-2">{blogData.blogViews}</p>
                                                </div>
                                                {/* <div className="d-flex ms-2 align-items-center">
                                                    <img src={watch} alt="graduate" height={17}></img> <p className="ms-2">5 min</p>
                                                </div> */}
                                                <div className="d-flex ms-2 align-items-center">
                                                    <img src={date} alt="graduate" height={17}></img> <p className="ms-2">{blogDate}</p>
                                                </div>
                                            </div>

                                            <div className="bl-location">
                                                <Link to={`/blog/${blogData.slug}`}>
                                                    <img src={arrowright} alt="location" height={15}></img>
                                                </Link>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}


                    

                    {/* <Col md={4} className="mt-4">
                        <Card>
                            <Link to="/ArticalDetails">
                                <Card.Img variant="top" src={bl4} />
                            </Link>
                            <Card.Body>
                                <div className='exp-info'>
                                    <div className='pic'>
                                        <img src={exp} alt="exp"></img>
                                    </div>
                                    <h6>Jessica jasmine</h6>
                                </div>
                                <Card.Title>Including proper citations for
                                    every idea</Card.Title>
                                <Card.Text>
                                    E-learning is a type of learning conducted E-learning is a
                                    type of learning conducted digitally via electronic media
                                    digitally via electronic media
                                </Card.Text>
                                <div className='justify-content-between d-flex align-items-center'>
                                    <div className="d-flex mt-3">

                                        <div className="d-flex align-items-center">
                                            <img src={view} alt="graduate" height={16}></img> <p className="ms-2">5632</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={watch} alt="graduate" height={17}></img> <p className="ms-2">5 min</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={date} alt="graduate" height={17}></img> <p className="ms-2">July 28, 2021</p>
                                        </div>
                                    </div>

                                    <div className="bl-location">
                                        <img src={arrowright} alt="location" height={15}></img>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} className="mt-4">
                        <Card>
                            <Link to="/ArticalDetails">
                                <Card.Img variant="top" src={bl1} />
                            </Link>
                            <Card.Body>
                                <div className='exp-info'>
                                    <div className='pic'>
                                        <img src={exp} alt="exp"></img>
                                    </div>
                                    <h6>Jessica jasmine</h6>
                                </div>
                                <Card.Title>Including proper citations for
                                    every idea</Card.Title>
                                <Card.Text>
                                    E-learning is a type of learning conducted E-learning is a
                                    type of learning conducted digitally via electronic media
                                    digitally via electronic media
                                </Card.Text>
                                <div className='justify-content-between d-flex align-items-center'>
                                    <div className="d-flex mt-3">

                                        <div className="d-flex align-items-center">
                                            <img src={view} alt="graduate" height={16}></img> <p className="ms-2">5632</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={watch} alt="graduate" height={17}></img> <p className="ms-2">5 min</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={date} alt="graduate" height={17}></img> <p className="ms-2">July 28, 2021</p>
                                        </div>
                                    </div>

                                    <div className="bl-location">
                                        <img src={arrowright} alt="location" height={15}></img>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} className="mt-4">
                        <Card>
                            <Link to="ArticalDetails">
                                <Card.Img variant="top" src={bl3} />
                            </Link>
                            <Card.Body>
                                <div className='exp-info'>
                                    <div className='pic'>
                                        <img src={exp} alt="exp"></img>
                                    </div>
                                    <h6>Jessica jasmine</h6>
                                </div>
                                <Card.Title>Including proper citations for
                                    every idea</Card.Title>
                                <Card.Text>
                                    E-learning is a type of learning conducted E-learning is a
                                    type of learning conducted digitally via electronic media
                                    digitally via electronic media
                                </Card.Text>
                                <div className='justify-content-between d-flex align-items-center'>
                                    <div className="d-flex mt-3">

                                        <div className="d-flex align-items-center">
                                            <img src={view} alt="graduate" height={16}></img> <p className="ms-2">5632</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={watch} alt="graduate" height={17}></img> <p className="ms-2">5 min</p>
                                        </div>
                                        <div className="d-flex ms-2 align-items-center">
                                            <img src={date} alt="graduate" height={17}></img> <p className="ms-2">July 28, 2021</p>
                                        </div>
                                    </div>

                                    <div className="bl-location">
                                        <img src={arrowright} alt="location" height={15}></img>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
            </Container>











            
           

        </>
    )
}

export default Latestartical