import React from 'react'
import Footer from '../../Footer/Footer'
import PreparatoryCrashCourseBanner from './PreparatoryCrashCourseBanner'
import PreparatoryCrashCourseMain from './PreparatoryCrashCourseMain'

const PreparatoryCrashCourse = () => {
  return (
    <>
        <PreparatoryCrashCourseBanner />
        <PreparatoryCrashCourseMain></PreparatoryCrashCourseMain>
        <Footer></Footer>
    </>
  )
}

export default PreparatoryCrashCourse