import '../innerpage.css'
import { Container, Tabs, Tab, Row, Accordion, Col } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
const VisaMain = () => {


    return (
        <>  
            <Helmet>
                <title>Student Visa Services - Applying for Student Visa  | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content='ExpertGRAD offers student visa services to help you with the process of applying for a student visa. Our team of experts will guide you through the process and provide you with the resources you need to make your application successful. Learn more about our student visa services today.' />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Visa Assistance</h4>
                <p>For international students planning to study in the USA, one of the mandatory requirements is obtaining a student visa. A student visa allows a student to pursue a study program in a foreign country. Student visas are usually issued for short-term purposes. However, before qualifying for a student Visa, you must have already been accepted to a university or educational institution.</p>
                <p>
                    The exact type of Visa required depends upon the course of study and the type of school you plan to attend. Whether F-1, M-1, or J-1, each kind of student Visa has different requirements, and it is advisable to get complete information before applying for it.
                </p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Requirements for Student's Visa:</h4>
                <p>You will need the following documents for your student visa application: </p>

                <ul className='ps-0'>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Proof of admission:</strong> The first requirement is a confirmed admission letter or enrollment records to show that you have been accepted to study in a US university.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Valid Passport:</strong> You must have a valid passport with a validity of at least six months when you apply for your Visa. If your passport is about to expire soon, apply for a fresh one immediately.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Student Visa application form:</strong> Fill in complete details in the student visa application form. The form can be obtained from the US embassy/consulate.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Passport Size Pictures:</strong> Recent passport size photographs. The exact number of pictures varies from country to country.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Financial Papers:</strong> Proof that you can financially support yourself during your education. If your family supports you, provide financial statements from your family members. If you have a scholarship, you need to attach proof of that.
                    </li>

                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Civil documents:</strong>  You must submit your birth certificate, marriage certificate (if you are married), and CV.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>International student health insurance:</strong> For studying in the USA, you would be required to purchase a health insurance plan that covers the entire period of your studies.
                    </li>

                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Previous university records:</strong> If you have attended a university before applying for your foreign study program, don't forget to submit your university records for the student visa application.
                    </li>
                </ul>
            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Book Free Counselling'>
                            <h4 className='innertitle'>Book Free Counselling</h4>
                            <p>Sometimes applying for visas can be challenging, but our team of experts is here to ensure you get all the correct information, and in time.  </p>

                            <p>Just get in touch with an ExpertGRAD counselor who will be more than happy to provide you with information about the complete process and documents required.
                                Since Visa requirements are subject to change, our counsellors ensure you have access to the latest and authentic information. To make things easier, we even help translate and courier your documents.
                            </p>

                        </Tab>
                    </Tabs>
                </Row>
            </Container>

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Question </h3>
                    </Col>


                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: Is a student visa easy to get?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: For Indian students getting a Visa to study in the USA is not difficult if you have all valid documents, follow all visa requirements and give yourself plenty of time to complete and submit your paperwork.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: How much money is required for a student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: For an Indian student, the current Visa fee is Rs. 10,880 for F1, M1 and J1 visa types.  </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: What questions do they ask for a student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The questions asked in the Visa interview aim to understand why the student wants to study in the USA. The questions are related to reasons for studying in the USA, your career plans, university chosen, academic capabilities, financial status, and plans after completing the studies. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.4: Who can assist with the visa application?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: To get Visa assistance, you may get in touch with a Visa consultant or portals like ExpertGRAD, who can guide you with the complete process and all the documents required.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                    </Accordion>
                </Container>
            </section>

        </>
    )
}

export default VisaMain