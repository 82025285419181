import '../innerpage.css'
import { Container, Row, Col } from 'react-bootstrap'

import t1 from '../../../images/t1.svg'
import t3 from '../../../images/t3.svg'
import t4 from '../../../images/t4.svg'
import t5 from '../../../images/t5.svg'
import t9 from '../../../images/t9.svg'
import t11 from '../../../images/t11.svg'
import t13 from '../../../images/t13.svg'
import t14 from '../../../images/t14.svg'
import t10 from '../../../images/t10.svg'
import t2 from '../../../images/t2.png'
import React from 'react'
import { Helmet } from 'react-helmet'

const TestimonialsMain = () => {


    return (
        <>
            <Helmet>
                <title>Student Testimonials - Real Stories of Success and Satisfaction</title>
                <meta name='description' data-react-helmet="true" content="Explore authentic testimonials from students who have experienced our services. Hear about their journeys, successes, and the impact of our offerings on their academic and personal growth.
" />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Testimonial </h4>
            </Container>
            <Container>
                <Row className='d-md-flex'>
                <Col md={4} className="mb-md-4">
                            <div className='exptab'>
                                <p>ExpertGrad's study support program has helped me in my Academics a lot. The 24/7 service and real human mentors have boosted my interest in writing and confidence, and I've achieved high grades in my university assessments. I don't feel panicked when my lecturers or tutors are on holiday, because I know I can always rely on ExpertGrad. This is perhaps the best study support program for international students! Writing is not that hard when you have ExpertGrad by your side.
                                </p>

                                <div className='exp-info'>
                                    <div>
                                        <img src={t10} alt="exp" width={55}></img>
                                    </div>
                                    <div className='exp-details'>
                                        <h4>Reetu Singh </h4>
                                        <h5>Lucknow </h5>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} className="mb-md-4">
                            <div className='exptab'>
                                <p>ExpertGrad's study support program has been incredibly helpful for me as I learned to write at an academic level again after 10 years out of study. The advice is clear and concise, and the videos and links to other information are very helpful. I highly recommend ExpertGrad to anyone who needs support with their academic writing.
                                </p>

                                <div className='exp-info'>
                                    <div>
                                        <img src={t2} alt="exp" width={55}></img>
                                    </div>
                                    <div className='exp-details'>
                                        <h4>Mukund Choksi </h4>
                                        <h5>Gujrat </h5>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>I can&#39;t thank my mentors enough for their incredible 1-1 academic program. My mentor was a
                                true expert in the subject and provided me with personalized guidance and support throughout
                                the semester. Thanks to their assistance, I not only aced my courses but also gained a deeper
                                understanding of the subject matter. I highly recommend ExpertGRAD for anyone looking for
                                exceptional academic support. </p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t1} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Jaideep Singh </h4>
                                    <h5>Mumbai </h5>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>I was struggling with my calculus course, but ExpertGRAD&#39;s live tutoring sessions came to my
                                rescue. The tutors were not only knowledgeable but also patient and effective in explaining the
                                most challenging course concepts. With their help, I not only passed the course but also
                                developed a newfound appreciation for math. I couldn&#39;t have done it without ExpertGRAD.</p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t11} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Piyush Sharma </h4>
                                    <h5>Indore </h5>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>ExpertGRAD&#39;s academic assistance and online learning resources have been a game-changer for
                                me. The study materials they provided were comprehensive and of high quality. I was able to
                                access practice tests, lecture notes, and study guides that significantly improved my performance.
                                ExpertGRAD truly cares about students&#39; success.</p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t3} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Sahil Ahuja </h4>
                                    <h5>Bangalore </h5>
                                </div>
                            </div>
                        </div>
                    </Col>


                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>Your academic assistance was a lifesaver during my university years. Their 1-1 mentorship
                                helped me manage my coursework efficiently. The live tutoring sessions ensured I understood
                                the most challenging concepts. This made research projects and term papers less daunting.
                                ExpertGRAD made my academic journey enjoyable and successful.
                            </p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t4} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Rohit Kapoor </h4>
                                    <h5>Himachal</h5>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>I couldn&#39;t have achieved an HD grade without ExpertGRAD’s help. Their one-to-one academic
                                mentorship was tailored to my needs, and the live tutoring sessions were engaging and
                                productive. The free test prep resources were fantastic, covering everything from strategies to
                                practice tests. ExpertGRAD is the reason I got my dream grades in my coursework.
                            </p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t5} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Manish Singh </h4>
                                    <h5>Bihar </h5>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>ExpertGRAD&#39;s academic assistance program was instrumental in helping me excel in high
                                school. The student mentorship gave me the guidance I needed to navigate tough courses, while
                                the live tutoring sessions were invaluable for clarifying doubts. They offered all the support -
                                from practice exams to study guides, making studying a breeze. Thanks to ExpertGRAD, I
                                graduated with honors!
                            </p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t9} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Anil Jain </h4>
                                    <h5>Jaipur </h5>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>I am so grateful for your prompt academic support. Their online expert mentorship has helped
                                me stay on track with my studies. The live tutoring sessions are informative and interactive,
                                making learning enjoyable. This helped me save my time and get over the stress from multiple
                                college tasks. ExpertGRAD is the academic companion every student needs to succeed. </p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t13} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Palak Tiwari </h4>
                                    <h5>Delhi </h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="mb-md-4">
                        <div className='exptab'>
                            <p>The academic assistance helped me understand complex topics and set clear academic goals. The
                                live tutoring sessions were a big help when I needed quick answers. The comprehensive study
                                resources are a fantastic bonus. I can&#39;t thank ExpertGRAD enough for their support.</p>

                            <div className='exp-info'>
                                <div>
                                    <img src={t14} alt="exp" width={55}></img>
                                </div>
                                <div className='exp-details'>
                                    <h4>Simran Dhillon </h4>
                                    <h5>Punjab </h5>
                                </div>
                            </div>
                        </div>
                    </Col>


                </Row>

            </Container>

        </>
    )
}

export default TestimonialsMain