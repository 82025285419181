import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Footer from '../../Footer/Footer'
import { Link } from 'react-router-dom'
const PolicyConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Your Guide to Our Agreement</title>
        <meta name='description' data-react-helmet="true" content="Review our terms and conditions to understand the rules and agreements governing your use of our services or website. Stay informed about your rights and responsibilities when engaging with our platform." />
      </Helmet>
      <Container className='my-5 text-start'>
        <h4 className='innertitle'>Privacy Policy</h4>
        <p>At ExpertGRAD, the privacy of people visiting our website is important to us, and we
take all steps to safeguard it. The following section explains how we deal with your
personal information.</p>
        <h5>Who are we?</h5>
        <p>ExpertGRAD Educational Technologies, with its headquarter in Noida, Uttar Pradesh,
India (hereinafter referred to as ExpertGRAD), is one of the largest academic assistance
providers that offers end-to-end scholarly support to students pursuing higher education
abroad. We aim to provide excellent professional assistance to students and help them
achieve the best outcome for their academic needs.</p>

        <h5>How do we collect your information?</h5>
        <p>We collect your information through our website in various ways. All parts of our
website do not necessarily require you to provide information that can directly identify
you by name. For example, you may choose to browse the website without logging in.
However, the specific information we collect depends upon the pages you visit, the
features you use, how you use them, and the information you choose to provide. One of
the ways we gather information is when you visit our website and consent to our use of
cookies as per the terms of this policy. It enables us to collect your personal data.</p>

        <h5>Why do we collect personal data and how do we use it?</h5>
        <p>We are in the business of providing informational support to our customers. So, any
information collected on this website is done with the sole intention of customizing our
services and providing a more personal experience to our users.</p>
        <p>The information we collect is for self-use only, i.e., provide, maintain, and improve our
services. We do not sell the information about our users to any person or external
organization. The information is shared only in the manner described in this Privacy
Notice. You can use our website without divulging any personally identifiable
information, including your email address.</p>

        <h5>Protection of your information</h5>
        <p>At ExpertGRAD, your personal information is as dear to us as it is to you, which is why
we take all possible precautions to protect and safeguard your information. To begin, all
the information collected is stored on servers that are secured with passwords and
firewall protection. In addition, we have taken all reasonable physical, technical and
organizational precautions to check any loss, misuse, or alteration of your sensitive
personal details.</p>
        <h5>Personal information deletion request</h5>
        <p>The personal information we collect is only stored for the intended purpose. As a part of
our privacy policy, we delete personal data within ten years from the date of receiving it.</p>
        <p>However, in case you don’t want us to store this information even for that long, you may
write to us at <Link> contact@expertgrad.com</Link> and we will do the needful.</p>
        <h5>Third parties</h5>
        <p>We do not share your personal data with any third party. However, our websites may
include links to other websites whose privacy practices may differ from ours, and we do
not have any control over those websites. Hence, if you submit personal information to
any of those websites, your information is governed by their privacy policies. We
encourage that you read the privacy policy of any website you visit.</p>
      </Container>

      <Footer></Footer>
    </>
  )
}

export default PolicyConditions