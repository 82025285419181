import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import { Row, Container, Col } from 'react-bootstrap'

import aboutbanner from '../../../images/aboutbanner.svg'

const AboutusBanner = () => {
    return (
        <>
            <div className='innerbanner pt-md-4 pt-3'>
                <Header></Header>
                <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={aboutbanner} alt="ausguidline" className='animated2 infinite fadeInLeftRight'
                                style={{maxWidth:"90%"}}></img>
                            </Col>
                            <Col md={7}>
                                <h2>About<span> Us</span></h2>
                                <p>An insight of ExpertGRAD </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AboutusBanner