import React from 'react'
import Footer from '../../Footer/Footer'
import ForexServicesBanner from './ForexServicesBanner'
import ForexServicesBannerMain from './ForexServicesBannerMain'
import '../innerpage.css'



const ForexServices = () => {
  return (
    <>
        <ForexServicesBanner></ForexServicesBanner>
        <ForexServicesBannerMain></ForexServicesBannerMain>
        <Footer></Footer>
    </>
  )
}

export default ForexServices