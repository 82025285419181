import React, { useState } from 'react'
import './nav.css'
import '../../'
import { NavLink } from 'react-router-dom';

const Nav = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return (
        <nav>

            <NavLink to={``} id="resp-menu" className="responsive-menu" onClick={() => { setIsNavExpanded(!isNavExpanded) }}><i className="fa fa-reorder"></i> Menu</NavLink>

            <ul className={isNavExpanded ? "menu expanded" : "menu"}>
                {/* <li><NavLink to={``} className="homer"> Study Abroad
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i>
                </NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={``}>Study in Australia</NavLink>
                            <ul>
                                <li><NavLink to={`/australia/why-study-in-australia`}>Why study in Australia</NavLink></li>
                                <li><NavLink to={`/australia/top-universities`}>Top Universities</NavLink></li>
                                <li><NavLink to={`/australia/popular-courses`}>Popular  Courses</NavLink></li>
                                <li><NavLink to={`/australia/cost-of-studying-in-australia`}>Cost of Studying in Australia</NavLink></li>
                                <li><NavLink to={`/australia/application-process-admission`}>Admission & Application Process</NavLink></li>
                                <li><NavLink to={`/australia/sop-and-its-importance`}>SOP and its importance</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={``}>Study in Canada</NavLink>
                            <ul>
                                <li><NavLink to={`/canada/why-study-in-canada`}>Why study in Canada</NavLink></li>
                                <li><NavLink to={`/canada/top-universities`}>Top Universities </NavLink></li>
                                <li><NavLink to={`/canada/popular-courses`}>Popular  Courses</NavLink></li>
                                <li><NavLink to={`/canada/studying-in-canada`}>Cost of Studying in Canada</NavLink></li>
                                <li><NavLink to={`/canada/application-process-admission`}>Admission & Application Process</NavLink></li>
                                <li><NavLink to={`/canada/sop-and-its-importance`}>SOP and its importance</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={``}>Study in UK</NavLink>
                            <ul>
                                <li><NavLink to={`/uk/why-study-in-uk`}>Why study in UK</NavLink></li>
                                <li><NavLink to={`/uk/top-universities`}>Top Universities </NavLink></li>
                                <li><NavLink to={``}>Popular  Courses</NavLink></li>
                                <li><NavLink to={`/uk/cost-of-study-in-uk`}>Cost of Studying in the UK</NavLink></li>
                                <li><NavLink to={`/uk/application-process-admission`}>Admission & Application Process</NavLink></li>
                                <li><NavLink to={`/uk/sop-and-its-importance`}>SOP and its importance</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={``}>Study in USA</NavLink>
                            <ul>
                                <li><NavLink to={`/usa/why-study-in-usa`}>Why Study in USA</NavLink></li>
                                <li><NavLink to={`/usa/top-universities`}>Top Universities</NavLink></li>
                                <li><NavLink to={`/usa/usa-popular-courses`}>Popular  Courses</NavLink></li>
                                <li><NavLink to={`/usa/cost-of-studying-in-the-usa`}>Cost of Studying in the USA</NavLink></li>
                                <li><NavLink to={`/usa/application-process-admission`}>Admission & Application Process</NavLink></li>
                                <li><NavLink to={`/usa/sop-and-its-importance`}>SOP and its Importance</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </li> */}
                {/* <li><NavLink to={``} className="homer"> Test Preparation
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={`/ieltscoaching`}>IELTS Coaching</NavLink></li>
                        <li><NavLink to={`/toeflCoaching`}>TOEFL Coaching</NavLink></li>
                        <li><NavLink to={``}>PTE Coaching</NavLink></li>
                        <li><NavLink to={``}>Duolingo</NavLink></li>
                    </ul>
                </li> */}

                {/* <li><NavLink to={``}> Visa Services
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li className='w-100'><NavLink to={``}>Applying for Visa </NavLink>
                            <ul>
                                <li><NavLink to={`/visa-services/visa-guidelines-for-australia`}>Visa Guidelines for Australia</NavLink></li>
                                <li><NavLink to={`/visa-services/visa-guidelines-for-canada`}>Visa Guidelines for Canada</NavLink></li>
                                <li><NavLink to={`/visa-services/visa-guidelines-for-uk`}>Visa Guidelines for UK</NavLink></li>
                                <li><NavLink to={`/visa-services/visa-guidelines-for-usa`}>Visa Guidelines for USA</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li><NavLink to={``}> Moving Abroad
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={`/moving-abroad/student-housing`}>Student Housing</NavLink></li>
                        <li><NavLink to={`/moving-abroad/paying-for-foreign-currency-forex`}>Paying for foreign currency</NavLink></li>
                        <li><NavLink to={`/moving-abroad/bank-account`}>Bank account</NavLink></li>
                        <li><NavLink to={`/moving-abroad/insurance`}>Insurance </NavLink></li>
                        <li><NavLink to={`/moving-abroad/driving-license`}>Driving License</NavLink></li>
                    </ul>
                </li> */}
                <li><NavLink to={``}> International Academia
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={`/study-pattern`}>Study Pattern</NavLink></li>
                        <li><NavLink to={``}>Assignments</NavLink></li>
                        <li><NavLink to={`/grading-system`}>Grading system</NavLink></li>
                        <li><NavLink to={``}>Score better in assignments </NavLink></li>
                    </ul>
                </li>
                <li><NavLink to={``}>Study Support Program
                    <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={`/academic-support`}>Full Course/Module Academic Support</NavLink></li>
                        <li><NavLink to={`/crash-course`}>Preparatory Crash Course</NavLink></li>
                        <li><NavLink to={`/onlineassignmentbank`}>Online Assignment Bank</NavLink></li>
                        <li><NavLink to={`/academic-mentorship`}>One-to-One Academic Mentorship</NavLink></li>
                    </ul>
                </li>

                <li><NavLink to={``}> About <i className="fa fa-sort-desc ms-1" aria-hidden="true"></i></NavLink>
                    <ul className="sub-menu">
                        <li><NavLink to={`/about-us`}>About Us</NavLink></li>
                        <li><NavLink to={`/student-testimonials`}>Student Testimonials</NavLink></li>
                        <li><NavLink to={`/privacy-policy`}>Privacy Policy</NavLink></li>
                        <li><NavLink to={`/terms-and-condition`}>Terms and Conditions</NavLink></li>
                        <li><NavLink to={`/our-team`}>The Team</NavLink></li>
                        <li className='c-mobile'><NavLink to={`/contact-us`}>Contact Us</NavLink></li>

                    </ul>
                </li>
                <li><NavLink to={`/blog`}> Blog</NavLink></li>
                {/* <li><NavLink to={`/contact`}> </NavLink></li> */}
            </ul>
        </nav>
    );
}

export default Nav;