import React from 'react'
import TermsBanner from './TermsBanner'
import TermsConditions from './TermsConditions'

const Termsmain = () => {
  return (
    <>
        <TermsBanner></TermsBanner>
        <TermsConditions />
    </>
  )
}

export default Termsmain