import '../innerpage.css'
import { Container, Tabs, Tab, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
const BankAccountMain = () => {
    return (
        <>
            <Helmet>
                <title>Banking Services for International Students | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content='ExpertGRAD is the leading provider of banking services for international students. We offer a wide range of services to help you manage your finances while you are studying abroad. We have a team of experts who are dedicated to providing you with the best possible service and support. Contact us today!' />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Bank Account</h4>
                <p>Planning to travel abroad to study? Once the admission and visa formalities are done, it&rsquo;s time you pay attention to other requirements to make your stay hassle-free. One of the requirements is having an international bank account.</p>
                <p>Having an international bank account keeps your money safe and secure and helps you sort out your finances before you land in the host country. In addition, you may use your mobile to make payments and make your life easy. Moreover, some banks offer specialised products and discounts for students studying abroad.</p>
                <p>Services ExpertGrad offers for Opening an International Bank account.&nbsp;</p>
            </Container>
            
            <Container className='innerTabs'>
            <h5 className='mb-3 text-center'>Country and their Popular Banks</h5>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Australia'>

                            <h4 className='innertitle'>Australia</h4>
                            <p>Popularly known as CommBank, Commonwealth Bank of Australia is the most popular bank in Australia, especially among the student community.&nbsp;</p>
                            <p>The bank offers facilities like &lsquo;bank-on-the-go&rsquo; and language-enabled ATMs. The bank has an extensive network of branches and ATMs, even within the campus. Some of the branches have bilingual staff to cater to international students.</p>

                            <h5>Account Opening Process:</h5>
                            <p>You may open an account up to 3 months before coming to Australia. Once you land in Australia, visit a CommBank branch, and submit physical documents, your account gets activated.</p>
                            <p>To open a bank account, you must complete the banking application form by yourself. You must provide details like your full name, date of birth (as mentioned on the passport), e-mail address, mobile number, and your nominated arrival date. In addition, you would be asked to register for Netbanking and provide a secure password to access the account. You would also be required to nominate a branch close to your place of stay or work.</p>
                            <p>Once in Australia, visit the nominated branch. Your account will be activated once you submit the following documents.</p>
                            <ul className='secpnt ps-0'>
                                <li>Current passport (original)</li>
                                <li>Tax Identification Number (TIN) for each country of foreign tax residency.</li>
                                <li>Copy (physical or digital) of your visa</li>
                            </ul>
                        </Tab>
                        <Tab eventKey='tab-2' title='CANADA'>
                            <h4 className='innertitle'>CANADA</h4>
                            <p>The most popular bank, among students, in Canada, is Simplii Financial. Under its unique International Student Banking Offer (ISBO), the bank offers various student-friendly financial services.</p>
                            <p>Students can choose between the Student GIC program and Student Deposit program to help meet study permit requirements or pre-fund a Canadian Account. With a simple account opening procedure, usually, the request is processed the same day. You may perform unlimited transactions and even get a credit card with a limit of up to C$ 2,000.</p>
                        </Tab>
                        <Tab eventKey='tab-3' title='The UK'>
                            <h4 className='innertitle'>The UK</h4>


                            <p>The wholly owned subsidiary of ICICI Bank Limited, ICICI Bank UK PLC is one of the leading banks in the UK. The parent bank, ICICI Bank Limited, is India&rsquo;s leading private-sector bank. The Bank was incorporated in England and Wales as a private company with limited liability.&nbsp;</p>
                            <p>Due to Indian roots, the bank focuses on India-linked business and meeting the banking needs of the Indian community in the UK. The bank offers core services like local banking, remittance services to India and facilitating banking requirements in India. As the biggest USP, the bank allows Indian students to open and activate their accounts from India as well.</p>
                            <p>The bank offers a unique banking solution called HomeVantage Current Account. It is specially designed to meet your banking needs in the UK. This account gives you complete access to your money &ndash; 24x7.</p>
                            <p><strong>Account Opening Process:</strong></p>
                            <p>For Indian students, opening an account is a very simple step-by-step process. You may download their mobile app or visit their website. The remaining process is as follows:</p>
                            <ul className='secpnt ps-0'>
                                <li>Fill out the account opening form</li>
                                <li>Scan your original passport</li>
                                <li>Take a selfie and upload your signature</li>
                                <li>Your account will be opened immediately (subject to mandatory checks)</li>
                                <li>Activate internet and mobile banking</li>
                                <li>Receive Debit card within 7-10 working days.</li>
                            </ul>
                            <p><strong>Documents Required:&nbsp;</strong></p>
                            <p>Only the original passport for scanning</p>

                        </Tab>

                        <Tab eventKey='tab-4' title='The USA'>
                            <h4 className='innertitle'>The USA</h4>
                            <p>One of the most popular banks in the USA, Bank of America is also the most preferred bank for international students.</p>
<p>Through its 4,100 branches and 16,000 ATMs, the bank offers banking services specially designed to cater to high school students under the age of 24 years. Under this, the bank provides various finance tools that help students manage their spending. The students are provided with debit cards, online banking, and an overdraft protection facility. And the students are allowed to keep their bank accounts active even after graduation. The minimum balance required to maintain the account is $25.</p>
<h5>Documents Required:</h5>
                            <ul className='secpnt ps-0'>
                            <li>Copy of your valid passport with the student visa attached to it</li>
<li>Copy of the student visa and I-20</li>
<li>Acceptance letter from your university</li>
<li>Debit/ credit card from the bank account in your home country</li>
<li>A report of your credit history</li>
<li>Any other document, if required</li>
                            </ul>
                        </Tab>

                    </Tabs>
                </Row>
            </Container>

            
        </>
    )
}

export default BankAccountMain