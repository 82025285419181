import React from 'react'
import './aboutexp.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import t10 from '../../../images/t10.svg'
import t2 from '../../../images/t2.png'
import arrowleft from '../../../images/arrow-left.png'
import Subscribe from '../subscribe/Subscribe'
import { Link} from 'react-router-dom'

const aboutexp = () => {
    return (
        <>
            <div className='aboutexp py-5'>
                <Container>
                    <Row className='d-md-flex justify-content-center'>
                        <Col md={4}>
                            <div className='exptab'>
                                <p>ExpertGrad's study support program has helped me in my Academics a lot. The 24/7 service and real human mentors have boosted my interest in writing and confidence, and I've achieved high grades in my university assessments. I don't feel panicked when my lecturers or tutors are on holiday, because I know I can always rely on ExpertGrad. This is perhaps the best study support program for international students! Writing is not that hard when you have ExpertGrad by your side.
                                </p>

                                <div className='exp-info'>
                                    <div>
                                        <img src={t10} alt="exp" width={55}></img>
                                    </div>
                                    <div className='exp-details'>
                                        <h4>Reetu Singh </h4>
                                        <p className='mb-0'><i>2nd year undergraduate</i></p>
                                        <h5>Lucknow </h5>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className='exptab'>
                                <p>ExpertGrad's study support program has been incredibly helpful for me as I learned to write at an academic level again after 10 years out of study. The advice is clear and concise, and the videos and links to other information are very helpful. I highly recommend ExpertGrad to anyone who needs support with their academic writing.
                                </p>

                                <div className='exp-info'>
                                    <div>
                                        <img src={t2} alt="exp" width={55}></img>
                                    </div>
                                    <div className='exp-details'>
                                        <h4>Mukund Choksi </h4>
                                        <p className='mb-0'><i>Postgraduate</i></p>
                                        <h5>Gujrat </h5>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className='exptab'>
                                <div className='title'>
                                    What people say <span>about</span> us
                                </div>
                                <button className="btn-yellow">
                                <Link to={'/student-testimonials'}>View More <img src={arrowleft} alt="arrowleft" height={15}></img></Link>
                                </button>
                            </div>
                        </Col>

                        <Subscribe></Subscribe>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default aboutexp