import React from 'react'
import Footer from '../../Footer/Footer'
import AusBanner from './AusBanner'
import AusStudy from './AusStudy'
import '../innerpage.css'



const StudyinAus = () => {
  return (
    <>
        <AusBanner></AusBanner>
        <AusStudy></AusStudy>
        <Footer></Footer>
    </>
  )
}

export default StudyinAus