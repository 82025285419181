import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'

const PolicyBanner = () => {
  return (
    <>
        <div className='pt-4 skybg'>
                <Header></Header>
        </div>
    </>
  )
}

export default PolicyBanner