
import React, { useEffect } from 'react';

import './App.css';
import Home from './components/Home/Home';
import { Routes, Route } from 'react-router-dom';
import StudyinAus from './components/Innerpages/studyinaus/StudyinAus';
import StudyinUsa from './components/Innerpages/studyinusa/StudyinUsa';

/*--visa guide--*/
import Visa from './components/Innerpages/visa/Visa';
import SguideLine from './components/Innerpages/sguideline/SguideLine';
import UsaVisaguideline from './components/Innerpages/usavisaguideline/UsaVisaguideline'
import CanadaVisa from './components/Innerpages/canadavisa/CanadaVisa';
import AustraliaVisa from './components/Innerpages/australiavisa/AustraliaVisa';
import UkVisa from './components/Innerpages/ukvisa/UkVisa';
import Contact from './components/Innerpages/contact/Contact';
import Blog from './components/Innerpages/blog/Blog';
import ArticalDetails from './components/Innerpages/blog/ArticalDetails';
import StudentHousing from './components/Innerpages/studenthousing/StudentHousing'

import TypicalStudy from './components/Innerpages/typicalstudy/TypicalStudy'
import ForexServices from './components/Innerpages/forexservices/ForexServices'
import IeltsCoaching from './components/Innerpages/ieltscoaching/IeltsCoaching';
import ToeflCoaching from './components/Innerpages/toeflCoaching/ToeflCoaching';
import PteCoaching from './components/Innerpages/ptecoaching/PteCoaching';
import Duolingo from './components/Innerpages/duolingo/Duolingo';
import BankAccount from './components/Innerpages/banckaccout/BankAccount';
import Insurance from './components/Innerpages/insurance/Insurance';
import DrivingLicense from './components/Innerpages/drivinglicense/DrivingLicense';
import Hostcountry from './components/Innerpages/hostcountry/Hostcountry';
import Assignments from './components/Innerpages/assignments/Assignments';
import GradingSystem from './components/Innerpages/gradingsystem/GradingSystem';
import Howtoansure from './components/Innerpages/howtoansure/Howtoansure';
import OnlineAssignmentBank from './components/Innerpages/onlineassignmentbank/OnlineAssignmentBank';
import FullcourseAcademicSupport from './components/Innerpages/fullcourseacademicsupport/FullcourseAcademicSupport';
import PreparatoryCrashCourse from './components/Innerpages/preparatorycrashcourse/PreparatoryCrashCourse';
import Aboutus from './components/Innerpages/aboutus/Aboutus';
import Ourteam from './components/Innerpages/ourteam/Ourteam';
import Offer from './components/Innerpages/offer/Offer';

import Testimonials from './components/Innerpages/testimonials/Testimonials';

import ReactGA from 'react-ga';
import Termsmain from './components/Innerpages/terms/Termsmain';
import Policymain from './components/Innerpages/policy/Policymain';

import UkWhyStudyUk from './components/Innerpages/studyinuk/UkWhyStudyUk';
import UkTopCourses from './components/Innerpages/studyinuk/UkTopCourses';
import OnetoOneAcademicMentorship from './components/Innerpages/onetooneacademicmentorship/OnetoOneAcademicMentorship';
const TRACKING_ID = "UA-187147430-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/australia/study-in-australia' element={<StudyinAus />} />

        <Route path='/uk/why-study-in-uk' element={<UkWhyStudyUk />} />
        <Route path='/uk/uk-popular-courses' element={<UkTopCourses />} />
        
        <Route path='/usa/study-in-usa' element={<StudyinUsa />} />
        
        <Route path='/visa-services/applying-for-visa' element={<Visa />} />
        <Route path='/studyvisa' element={<SguideLine />} />
        <Route path='/visa-services/visa-guidelines-for-usa' element={<UsaVisaguideline />} />
        <Route path='/visa-services/visa-guidelines-for-canada' element={<CanadaVisa />} />
        <Route path='/moving-abroad/paying-for-foreign-currency-forex' element={<ForexServices />} />
        <Route path='/visa-services/visa-guidelines-for-australia' element={<AustraliaVisa />} />
        <Route path='/visa-services/visa-guidelines-for-uk' element={<UkVisa />} />
        
        <Route path='academic-mentorship' element={<OnetoOneAcademicMentorship />} />
        <Route path='/study-pattern' element={<TypicalStudy />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='ieltscoaching' element={<IeltsCoaching />} />
        <Route path='toeflCoaching' element={<ToeflCoaching />} />
        <Route path='ptecoaching' element={<PteCoaching />} />
        <Route path='/moving-abroad/bank-account' element={<BankAccount />} />
        <Route path='/moving-abroad/insurance' element={<Insurance />} />
        <Route path='crash-course' element={<PreparatoryCrashCourse />} />
        <Route path='/moving-abroad/driving-license' element={<DrivingLicense />} />
        <Route path='hostcountry' element={<Hostcountry />} />
        <Route path='assignments' element={<Assignments />} />
        <Route path='grading-system' element={<GradingSystem />} />
        <Route path='howtoansure' element={<Howtoansure />} />
        <Route path='/onlineassignmentbank' element={<OnlineAssignmentBank />} />
        <Route path='/academic-support' element={<FullcourseAcademicSupport />} />
        <Route path='duolingo' element={<Duolingo />} />
        <Route path='blog' element={<Blog />} />
        <Route path='/about-us' element={<Aboutus />} />
        <Route path='/our-team' element={<Ourteam />} />
        <Route path="offer" element={<Offer />} />
        <Route path="/student-testimonials" element={<Testimonials />} />

        {/* <Route path='/articaldetails' element={<ArticalDetails />} /> */}
        <Route path='/blog/:title' element={<ArticalDetails />} />
        {/* <Route path='/blog2' element={<Blog2 />} />
        <Route path='/blog3' element={<Blog3 />} /> */}
        {/* <Route path='/articaldetails' element={<ArticalDetails />} /> */}
        <Route path='/studentvisa' element={<SguideLine />} />
        <Route path='/moving-abroad/student-housing/' element={<StudentHousing />} />

        <Route path='/terms-and-condition' element={<Termsmain />} />
        <Route path="/privacy-policy" element={<Policymain />} />
      </Routes>
    </>
  );
}

export default App;
