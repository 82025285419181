import React from 'react'
import Footer from '../../Footer/Footer'
import OfferBanner from './OfferBanner'
import OfferMain from './OfferMain'
import '../innerpage.css'


const Offer = () => {
  return (
    <>
        <OfferBanner></OfferBanner>
        <OfferMain></OfferMain>
        <Footer></Footer>
    </>
  )
}

export default Offer