import '../innerpage.css'
import { Container, Col, Accordion, } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
function InsuranceMain() {
  return (
    <>
             <Helmet>
                <title>Student Health Insurance for Study Abroad| ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content="Whether you're looking for comprehensive coverage or just a few basic benefits, we can help you find the perfect student health insurance for your study abroad experience. Contact us today to learn more about our plans and get started on your trip!" />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Study Abroad Insurance</h4>
                <p>You have ensured your future with admission to your dream university. How about doing the same with your life? Get the right Study Abroad Insurance and have a pleasant stay abroad.</p>  
            </Container>

            <Container className='studymain'>
                <h4 className='innertitle'>ExpertGrad Support</h4>
                <p>As you study abroad, the chances are high that you may be living alone. During your stay, in case there is any emergency – medical or non-medical, having an insurance policy would help you tackle the tough times better. In all such unfortunate situations, ExpertGRAD is there like your ‘3 am friend’ to bail you out of difficult situations.</p>

                <p><strong>The various support services provided are:</strong></p>
                <ul className='ps-0'>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Travel Assistance: </strong> If something goes wrong during your stay abroad, ExpertGRAD stands beside you to help in any unforeseen situation. This includes any medical emergency during your travel. </li>
                    
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Emergency Evacuation Service: </strong> The memories of the Ukraine-Russia war must be fresh in your mind. As soon as the war broke out, thousands of Indian students were stuck in various parts of Ukraine. In such situations, where you need to be evacuated in case of any emergency like this, ExpertGRAD helps students by offering Emergency Evacuation Services.<br></br>
                    Similarly, in case of a medical emergency, if you need any assistance, 
ExpertGRAD will provide the necessary support to ensure your safety and security.

                    </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Airport transfers:</strong> From your home airport to your destination campus, ExpertGRAD takes care of you as you move. In case your baggage is lost or delayed at the airport, ExpertGRAD provides assistance. This includes loss or theft of your luggage at the airport, in transit, or on the road.</li>
                </ul>

            </Container>            

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Questions </h3>
                    </Col>


                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: Is insurance mandatory for international students?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: For most countries like the USA, UK, and Australia, it is mandatory for students to buy travel insurance. Many Schengen countries don’t allow a student visa unless the students buy an international student travel insurance policy.</p>

                                <p>However, even if not mandatory, it is advisable to buy one to ensure medical and financial safety in a foreign land. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: What is the importance of insurance for students going abroad?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Living alone in a foreign country can be quite a challenge. There are possibilities when you have unwanted situations like sickness, accident, loss of baggage etc. However, once you buy student insurance, you can rest assured that there is someone to look after you whenever and wherever you need it. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: What are the benefits of international travel insurance?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: One of the most significant benefits of opting international travel insurance is to cover any unforeseen medical expenses while you are studying or travelling abroad. It also covers you against any loss of checked-in baggage, passport etc. International travel insurance also covers you for any change in the travel plan by the airlines or cancellation of your booking by the hotel.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: Where can you get study abroad insurance?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: There are various insurance companies offering student study abroad travel insurance. You may compare the same and select the best one that suits your requirements.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>
            </section>
        </>
  )
}

export default InsuranceMain