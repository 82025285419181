import React from 'react'
import Footer from '../../Footer/Footer'
import DrivinglicenseBanner from './DrivinglicenseBanner'
import DrivinglicenseMain from './DrivinglicenseMain'
import '../innerpage.css'

function DrivingLicense() {
  return (
    <>
        <DrivinglicenseBanner></DrivinglicenseBanner>
        <DrivinglicenseMain></DrivinglicenseMain>
        <Footer></Footer>
    </>
  )
}

export default DrivingLicense