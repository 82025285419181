import React from 'react'
import Nav from '../Nav/Nav'
import { NavLink } from 'react-router-dom'

import './header.css'
import logo from '../../images/logo2.png'
// import search from '../../images/search.svg'
// import calc from '../../images/date.svg'
// import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const Header = () => {
  // const history = useNavigate();
        // const handlePlaceHolder = () => {
    //   console.log('Focused')
    // }


  return (
    <>
      <div className='menu-container'>
        <div className='d-flex justify-content-between'>
          <NavLink exact="true" className='isActive' to="/">
            <img src={logo} alt="logo" className='logo'></img>
          </NavLink>
          <div className='d-flex align-items-center m-width'>
            {/* <div className='topsearch'>
              <img src={search} alt="#"></img>
              <input type='text' placeholder='Search courses, universities or scholarships'></input>
              <ReactSearchAutocomplete
                        items={items}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        autoFocus
                        formatResult={formatResult}
                        placeholder='Search courses, universities or scholarships'
                    />
            </div> */}
            {/* <div className="topinfo">
                <img src={calc} alt="Date"></img>
                <h6 className='mb-0 ms-2'>Events</h6>
              </div> */}

              <NavLink className="topinfo user" to={`/offer?utm_source=BMF_Alliance_CD&utm_medium=Aliiances_Domestic&utm_campaign=BMF_EGaus`}>
                <i className="fa fa-gift" aria-hidden="true"></i>
                <h6 className='mb-0 ms-2'> Offer</h6>
              </NavLink>
              <NavLink className="topinfo user" to={`/contact-us`}>
                <i className="fa fa-phone" aria-hidden="true"></i>
                <h6 className='mb-0 ms-2'> Contact Us</h6>
              </NavLink>

          </div>
        </div>
      </div>
      <Nav></Nav>


    </>
  )
}

export default Header