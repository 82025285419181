import '../innerpage.css'
import { Container, Col, Accordion, Tabs, Tab, Row } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'

function DuolingoMain() {
  return (
    <>
            <Container className='studybg my-5'>
                <p>Studying abroad is already an enormous emotional challenge. Make sure you don't face the same in financial matters. Get the proper Forex assistance when and where you need it.</p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Forex Assistants for currency exchange :</h4>
                <p>As the name indicates, Forex (Foreign Exchange) involves converting one currency into another. In today's global world, currency rates fluctuate at the drop of a hat. Hence, it is critical to have a trusted partner who can give you the best exchange rates and help you in financial matters on foreign soil.
                </p>
                <p>There are various ways in which students can avail of these services. Some of these are listed below:</p>

                <ul className='ps-0'>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Forex Card: </strong> Looking for a smart and economical way of carrying Forex? A forex card is what you need. The card allows you to exchange foreign currency at interbank/ zero margin (above ₹1lakh)* exchange rates. Moreover, these cards can be used worldwide for online transactions at ATMs and merchant outlets abroad without paying any additional foreign fee. </li>
                    
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Demand Drafts: </strong> Looking for a more conventional and paper-based method of sending money abroad? In that case, we recommend you go for foreign currency Demand Drafts. These physical paper drafts can be carried internationally or couriered/mailed to a beneficiary abroad. DDs are secured payment instruments and guarantee the availability of funds since a bank issues them. If lost or stolen, these can be reissued. Demand drafts come with no hidden fees and are often used to save the sending and recipient charges that banks commonly apply in case of wire transfers.
                    </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Traveller's Cheques:</strong> Prefer carrying money with you but don't want to carry the risk of losing them? Well, you have a way out. Traveller's Cheques allow you to move with 'cash'. Traveller cheques are accepted in most countries. Since they are issued by a bank against cash payment, there is no risk of the cheque 'bouncing'. Also, in case the traveller's cheques are lost or stolen, you can inform the bank and reissue new cheques.</li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>International Debit Cards:</strong> Looking for the most popular, tech-friendly way of transferring money. Then you should go for an International Debit Card. The money is deposited in a local bank in India and can be used to shop online or at a merchant outlet and even to withdraw cash at ATMs. The money withdrawn is converted into your currency on that day and is debited to your account. This gives you the flexibility of having one card to manage multiple currencies. You only need to ensure there is sufficient balance in your bank account.
                    </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Wire Transfers:</strong> Looking for a convenient and quick way to transfer money abroad? You definitely need a wire transfer. The international wire transfer is a direct bank-to-bank transfer where money from an Indian Bank is transferred to your beneficiary's bank account abroad. This is a fast and convenient way to transfer money abroad. Once the transfer is processed, the beneficiary can get the funds within 12-48 working hours.
                    </li>

                </ul>

            </Container>

            <Container className='innerTabs'>
<Row>
                <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                    <Tab eventKey='tab-1' title='Advantages of using Forex Services'>
                        <h4 className='innertitle'>Advantages of using Forex Services</h4>
                        <p>For international students, getting good forex services is critical to managing any emergency on foreign soil. Having quick and easy access to money can save you from landing in a miserable situation. The various advantages of using forex services are listed below: </p>

                        <ul className='secpnt ps-0'>
                            <li>It gives you easy liquidity in case of any emergency</li>
                            <li>Carrying too much cash involves the risk of money getting lost or stolen</li>
                            <li>Most forex services provider offer tracking services to know the status of your funds' transfer</li>
                        </ul>

                    </Tab>
                    <Tab eventKey='tab-2' title='BookMyForex'>
                        <h4 className='innertitle'>BookMyForex</h4>
                        <p className='mb-4'>Working in a strategic partnership with ExpertGRAD, BookMyForex is India's first and largest foreign exchange platform. It provides money exchange remittance services at the best rates and superior service to the students.  </p>

                        <p> For the service providers also, it offers Forex as a Service (FaaS) solution that allows them to begin offering forex services to students with zero investments and no staff, to begin with. One can seamlessly start offering international money transfer services, forex cards and currency services to students across all platforms.  </p>
                    </Tab>
                    <Tab eventKey='tab-3' title='Benefits for the Students'>
                        <h4 className='innertitle'>Benefits for the Students</h4>
                        <ul className='secpnt ps-0'>
                            <li>Live & best forex rates. Forex Card at flat interbank rates (as you see on Google)</li>
                            <li>Simple Order booking flow</li>
                            <li>Best Card Delivery TAT in the industry</li>
                            <li>Tracking and complete control through BookMyForex App</li>
                            <li>Exceptional Service Standards  </li>
                            <li>24X7 Booking options</li>
                        </ul>
                    </Tab>

                    <Tab eventKey='tab-4' title='Benefits for Service Providers'>
                        <h4 className='innertitle'>Benefits for Service Providers</h4>
                        <ul className='secpnt ps-0'>
                            <li>Zero human intervention, Zero forex knowledge required</li>
                            <li>The marketplace advantage</li>
                            <li>Flexible Revenue Model</li>
                            <li>Zero Risk Offering  </li>
                            <li>Track orders and revenue generated in real-time.</li>
                        </ul>
                        <p><strong>Start Your Journey </strong></p>
                    </Tab>

                </Tabs>
            </Row>
            </Container>

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequency Ask Questions </h3>
                    </Col>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: Why is the USA best for study?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The universities in the USA are among the best in the world. Quality education, flexible course content, excellent support system, and vibrant campus life are some of the reasons which make the USA best for study.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: Is studying in the USA difficult?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A large number of international students in the USA proves that studying in the USA is not that difficult. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: Is it worth studying in the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The quality of education and the multi-cultural environment offered in USA universities make the students well-prepared to handle the professional and interpersonal requirements of working in a global environment.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: Is it possible to study in the USA after the 12th?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Yes. Students may enroll on undergraduate courses in the USA.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5: What exams are required to study in the US?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: There are various qualifying exams that one needs to clear before studying in the USA. These exams are.</p>

                                <ul className='secpnt ps-0'>
                                    <li>GRE (Graduate Record Examination)    </li>
                                    <li>GMAT (for MBA)</li>
                                    <li>SAT (for Undergraduate courses)</li>
                                    <li>Subject Tests on the SAT (for Undergraduate courses)</li>
                                    <li>Advanced Placement Tests (for Undergraduate courses)
                                        In addition, students need to clear a test called TOEFL (Test of English as a Foreign Language) to prove their eligibility to understand basic English.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6: Is studying in the USA costly?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Studying in the USA is a bit expensive, but one can manage the expenses with proper planning.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7: What is the minimum cost to study in the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The minimum cost to study in the USA ranges between $8,000 to $10,000 a year.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q.8: What are the affordable cities to study in the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Some of the most affordable to live in the USA while studying are San Diego, Pittsburgh, Atlanta, Dallas, Houston, Chicago, Boston, and Philadelphia.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Container>
            </section>
        </>
  )
}

export default DuolingoMain