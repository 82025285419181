import React from 'react'

import { Container } from 'react-bootstrap'

import { Helmet } from 'react-helmet'
const PreparatoryCrashCourseMain = () => {
    return (
        <>
            <Helmet>
                <title>Crash Course - Fast-Track Your Learning Adventure"</title>
                <meta name='description' data-react-helmet="true" content='Embark on a rapid learning journey with our crash course offerings before joining an international university. Gain essential knowledge and skills efficiently, helping you achieve your educational objectives in record time.
' />
            </Helmet>
            <Container className='studybg my-5'>
                <p>College coursework can be more challenging and demanding than high school. Enrolling in our
                    Preparatory Crash Course can set you up for success, reduce the stress associated with the
                    transition, and provide numerous benefits that will serve you well throughout your academic
                    journey and beyond. It is designed to grant you additional support in specific areas of academic
                    writing and bridges the gap between your current knowledge and the academic expectations of
                    an international college, ensuring you&#39;re academically prepared to tackle your courses from day
                    one. </p>

                <p>The 10-day Preparatory crash course covers a broad range of topics such as basics of academic
                    writing, research methodologies, tackling writing tasks like essays, research proposal,
                    thesis/dissertation, reports, business plan, powerpoint presentations, and referencing and
                    citations. You also have the option to choose between in-person and online classes along with
                    customizable packages for your ease.</p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Top Reasons to Choose Our Preparatory Crash Courses</h4>
                <p>When you enter college with a strong foundation in the subjects or skills relevant to your major,
                    you&#39;re likely to feel more confident. This confidence can help you participate actively in class
                    discussions, approach professors with questions, and excel in your assignments and exams. Here
                    are top four reasons to opt for our crash course: </p>
                <ul className='secpnt ps-0'>
                    <li><strong>Expert Instructors:</strong> Our instructors are seasoned professionals who have mastered their subjects
                        and teaching techniques. They are dedicated to your success and are committed to delivering
                        high-quality instruction.</li>

                    <li><strong>Proven Results:</strong> Our preparatory crash courses have a track record of success. Majority of our
                        students have seen significant improvements in their grades, test scores, and overall academic
                        performance after taking our courses. </li>

                    <li><strong>Affordability:</strong> We believe that quality education should be accessible to all. Our competitive
                        pricing ensures that you get the support you need without breaking the bank. </li>
                    <li><strong> Supportive Community:</strong> Join a community of like-minded students who are also striving for
                        academic success. Exchange ideas, experiences, and support one another on your journey.</li>
                </ul>

                <h4 className='innertitle mt-5'>ExpertGRAD: A Synonym for Easy Learning</h4>
                <p>Don&#39;t let the fast-paced nature of college life overwhelm you. At ExpertGRAD Educational
                    Technologies, we are committed to helping you thrive in the academic arena. Our preparatory
                    crash course will accelerate your college success by providing you with the foundational
                    knowledge, skills, and confidence needed to excel in your coursework and exams. </p>

                <p>Whether you&#39;re preparing for standardized tests, tackling challenging subjects, or enhancing your
                    study skills, we have a course tailored to your needs. Join us in your pursuit of academic
                    excellence and embark on a journey to achieve your full potential. Your college success starts
                    here. Take the first step today! </p>
            </Container>

        </>
    )
}

export default PreparatoryCrashCourseMain