import '../innerpage.css'
import { Container, Row } from 'react-bootstrap'

import mayank from '../../../images/mayank.png'
import amitkumar from '../../../images/amit-kumar.png'
import dheeraj from '../../../images/dheeraj.png'
import rahul from '../../../images/rahul.png'
import sudhanshu from '../../../images/sudhanshu.png'
import akash from '../../../images/aakash.png'
import { Helmet } from 'react-helmet'
const TeamMain = () => {


    return (
        <>
            <Helmet>
                <title>Our Team - Meet the Faces Behind Our Success</title>
                <meta name='description' data-react-helmet="true" content="Get acquainted with the talented and dedicated individuals who make up our team. Learn about their expertise, passion, and commitment to delivering top-notch services and solutions." />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>ExpertGRAD leaders</h4>
                <p>Introducing our sharp leaders, putting efforts to make academic journey a smooth sail! </p>
            </Container>

            <Container className='studymain mb-5'>
                <div className='d-md-flex'>
                    <div className='teamlead me-4'>
                        <div className='pic' style={{ backgroundColor: "#F9C015" }}>
                            <img src={sudhanshu} alt="founder" className='img-fluid'></img>
                        </div>
                        <div className='teaminfo'>
                            <h5>N N Sudhanshu </h5>
                            <p className='deg'  style={{ background: "#F9C015" }}>Founder</p>
                        </div>
                    </div>

                    <div>
                        <p><em>“A team is as good as a leader and vice versa.”</em></p>
                        <p>Mr N.N.Sudhanshu, the incredible leader of ExpertGRAD, started the organisation to provide students with every kind of assistance they need for their study abroad plan. From the initial stages when a student decides to study abroad to the last minute, ensuring they settle down in the new country without any discomfort, Mr Sudhanshu has built a team of experts who guide students throughout the process.
                        </p>

                        <p>The leading man of the organisation joined hands with people sharing similar dreams and giving opportunities to many fresh talents to work in his firm. The number of academic mentors, leaders, associates and hard-working employees is increasing every day. We are en route towards joining hands with more such potential and incredibly talented humans who are willing to pass on their wisdom and guidance to those in need. The mission of our organisation remains the same, to provide A to Z assistance to students planning to study abroad.
                        </p>


                    </div>
                </div>


            </Container>

            <Container className='studymain mb-5'>
                <Row className='d-md-flex justify-content-between'>
                    <div className='order1'>
                        <p> Besides building a path for students who wish to study abroad, ExpertGRAD is also there for experts with immense knowledge and experience related to the study abroad process. Building a bridge between needs and supply, ExpertGRAD’s team is the one-stop solution for all study abroad requirements. Our experts contain answers to all your questions, queries, and doubts, ensuring no student misses their once-in-a-lifetime opportunity to study abroad at their dream university.
                        </p>
                        <p>Building this dream stronger in every aspect is our company’s Vice President, Mr Aakash Shandilya. One of the strongest pillars of ExpertGRAD, whose operational skills are unimaginable and with his continuous efforts, he is leading the ExpertGRAD team to achieve new heights. Knowing how crucial the entire process of managing everything is, from building teams, collaborating with different service providers, planning effective marketing strategies, and executing everything on the ground level. Mr Shandilya is the man with the plan for ExpertGRAD.
                        </p>


                    </div>

                    <div className='teamlead order2'>
                        <div className='pic' style={{ background: "#57BEDF" }}>
                            <img src={akash} alt="founder" className='img-fluid'></img>
                        </div>
                        <div className='teaminfo' style={{ background: "#AAD6BD" }}>
                            <h5>Aakash Shandilya </h5>
                            <p className='deg' style={{ background: "#57BEDF" }}>Vice President</p>
                        </div>
                    </div>
                </Row>

            </Container>

            {/* <div className='teamlead'>
                <div className='pic hover-switch' style={{ background: "#3FA474" }}>
                
                    <img src={ukvisaguide} alt="founder" className='img-fluid'></img>
                    <img src={ukbanner} alt="founder" className='img-fluid'></img>
                    
                </div>
                <div className='teaminfo' style={{ background: "#F9C015" }}>
                    <h5>Pallavi Rohira </h5>
                    <p className='deg' style={{ background: "#57BEDF" }}>Brand Rep. & Sr. Cons</p>
                </div>
            </div> */}

            <Container className='mb-5 all-team'>

                <h4 class="innertitle m-auto mb-4">The Key Members</h4>
                <div className="grid-container">

                    <div>
                        <div className='teamlead'>
                            <div className='pic'>
                                <img src={dheeraj} alt="founder" className='img-fluid'></img>
                            </div>
                            <div className='teaminfo' style={{ background: "#8DCEB0" }}>
                                <h5>Dheeraj Kumar </h5>
                                <p className='deg'>Sr. Executive</p>
                                <h6>(Human Resource)</h6>
                            </div>
                        </div>
                    </div>

                    {/* <div>
                        <div className='teamlead'>
                            <div className='pic' style={{ background: "#3FA474" }}>
                                <img src={ukvisaguide} alt="founder" className='img-fluid'></img>
                            </div>
                            <div className='teaminfo' style={{ background: "#F9C015" }}>
                                <h5>Vani Madhuri </h5>
                                <p className='deg' style={{ background: "#57BEDF" }}>Executive</p>
                                <h6>(Human Resource)</h6>
                            </div>
                        </div>
                    </div> */}

                    <div>
                        <div className='teamlead'>
                            <div className='pic'>
                                <img src={mayank} alt="founder" className='img-fluid'></img>
                            </div>
                            <div className='teaminfo' style={{ background: "#F9B5D3" }}>
                                <h5>Mayank Saxena </h5>
                                <p className='deg'>Assistant Manager </p>
                                <h6>(Client Relations & Operation) (Sales)</h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='teamlead'>
                            <div className='pic'>
                                <img src={rahul} alt="founder" className='img-fluid'></img>
                            </div>
                            <div className='teaminfo' style={{ background: "#F9C015" }}>
                                <h5>Rahul Bhatnagar</h5>
                                <p className='deg'>Support Engineer</p>
                                <h6>(Information Technology) (IT)</h6>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='teamlead'>
                            <div className='pic'>
                                <img src={amitkumar} alt="founder" className='img-fluid'></img>
                            </div>
                            <div className='teaminfo' style={{ background: "#F9B5D3" }}>
                                <h5>Amit Kumar</h5>
                                <p className='deg'>Manager</p>
                                <h6>(Accounts & Finance)</h6>
                            </div>
                        </div>
                    </div>


                </div>

                <p>If you are also willing to join this incredible team of exceptional talent, where work is worship and the environment is fun, then we welcome you with open arms. Walking along with our company’s motto, every EGian at ExpertGRAD believes in “Together We Grow.” And if you are also looking for a work culture and environment where your job role makes you learn things, and you help people learn some valuable aspects of their study abroad journey, then we are pleased to welcome you.

                </p>
                <p>Join ExpertGRAD today to build your dream of assisting others with your knowledge and being part of the fantastic workforce. Connect today.
                </p>
            </Container>

        </>
    )
}

export default TeamMain