import React from 'react'
import Footer from '../../Footer/Footer'
import AboutusBanner from './AboutusBanner'
import AboutusMain from './AboutusMain'


const Aboutus = () => {
  return (
    <>       
       <AboutusBanner></AboutusBanner>
       <AboutusMain></AboutusMain>
       <Footer></Footer>
    </>
  )
}

export default Aboutus