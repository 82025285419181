import React from 'react'
import Header from '../../Header/Header';
import {Container, Row, Col} from 'react-bootstrap'

import ausbanner from '../../../images/ausbanner.svg'

const OnlineAssignmentBankBanner = () => {
    return (
        <>
            <div className='innerbanner pt-4'>
                <Header></Header>
                <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={ausbanner} alt="ausbanner" className='animated2 infinite fadeInLeftRight'></img>
                            </Col>
                            <Col md={7}>
                                <h2>OnlineAssignment <br></br><span>Bank </span></h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default OnlineAssignmentBankBanner;