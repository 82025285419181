import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import { Row, Container, Col } from 'react-bootstrap'

import ukvisabanner from '../../../images/ukvisabanner.svg'

const UkVisaBanner = () => {
    return (
        <>
            <div className='innerbanner pt-4 ukbanner'>
                <Header></Header>
                <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={ukvisabanner} alt="ukvisabanner" className='animated2 infinite fadeInLeftRight'></img>
                            </Col>
                            <Col md={7}>
                                <h2>Visa Guidelines for <span>UK</span></h2>
                                <p>Your dream destination is 4964.04 miles away from your reality.   </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default UkVisaBanner