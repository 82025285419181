import '../innerpage.css'
import { Container, Tabs, Tab } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const TypicalStudyMain = () => {
    return (
        <>
            <Helmet>
                <title>Study Pattern - Unveiling Research Approaches and Methods</title>
                <meta name='description' data-react-helmet="true" content='Explore the conventional patterns and methodologies that shape typical research studies across various international universities courses. Gain insights into the common approaches used by students to conduct their research.
' />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Study Pattern Abroad </h4>
                <p>The various countries in the world differ a lot from each other. This difference can be seen in their history and culture and in how they lead their life and educate their students. The education systems around the world differ. This raises an obvious question – which country’s higher education best suits your career goals. 
                </p>
                <p>Below is a detailed account of the education systems in some of the major educational destinations worldwide.</p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Education System in the USA</h4>
                
                
            </Container>

            <Container className='innerTabs'>

                <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>

                    <Tab eventKey='tab-1' title='Education System in Canada'>
                        <h4 className='innertitle'>Education System in Canada</h4>
                        <p>The best place for settling after completing their studies, Canada is among the top countries for living and studying. In addition, it offers education comparable to that provided in the USA at a much lower cost. </p>

                        <p>Also, education is Canada is the responsibility of the provinces. So, there is no central authority in Canada. As a result, there is a great degree of variation in how universities are governed and education is imparted. Universities in Quebec, Ontario, and British Columbia offer courses across subjects which are in great demand due to their high level of teaching. </p>
                        <p>Most degree courses are 3-years long. However, they are slowly making way for 4-year degree programs.</p>
                    </Tab>
                    <Tab eventKey='tab-2' title='Education System in Australia'>
                        <h4 className='innertitle'>Education System in Australia</h4>
                        <p className='mb-4'>The education system in Australia is relatively autonomous, with minimal interference from the government. As a result, Australian universities are self-regulated, and that’s why there are variations in their teaching pedagogy. </p>

                        <p> Degree courses are usually 3-years long; with some exceptional honours courses requiring 4-years of study.</p>

                        <p>The universities promote innovation, creativity, and independent thinking among their students. This makes education challenging but fun and rewarding. </p>
                    </Tab>
                    <Tab eventKey='tab-3' title='Education System in the UK'>
                        <h4 className='innertitle'>Education System in the UK</h4>
                        <p>The UK education system has 4 major parts - primary education, secondary education, further education and higher education. For international students, the last level is of the most importance. </p>
                        <p>Like India, the UK, too, has several colleges attached to one university. So, while colleges are responsible for imparting education, universities are responsible for conducting exams and issuing degrees. </p>
                        <p>Most degree courses continue for 3 years. However, of late, ‘sandwich courses’ are becoming popular, which take 4 years and involve one year of work experience, usually in the third year.  </p>
                        <p>Master’s programs, taken after graduation, are of shorter duration. On the other hand, professional courses like medicine, veterinary, law etc., are more extended programs and may take up to 5 years.</p>

                    </Tab>
                
                    <Tab eventKey='tab-4' title='Education System in the USA'>
                        <h4 className='innertitle'>Education System in the USA</h4>
                        <p>The country with the most international students, the USA, has a unique education system - in terms of the number of courses offered and the course content. There is a strong emphasis on liberal art education. Students may study various subjects before majoring in a particular field, which may be different from what he/she has learned in the past.  </p>

                        <p>Besides these liberal art colleges, the USA has many research universities. They are busy producing world-class research and researchers, making the US universities among the top ones in the world. </p>
                        <p>At both places, a degree is granted after 4 years of study.</p>
                    </Tab>
                   
                </Tabs>

            </Container>

           
        </>
    )
}

export default TypicalStudyMain