import '../innerpage.css'
import { Container, Tabs, Tab, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const StudentHousingMain = () => {
    return (
        <>
            <Helmet>
                <title>Student Housing for Study Abroad All You Need To Know| ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content="ExpertGRAD offers the latest and most comprehensive guide on student housing for study abroad. Learn about the different types of student housing, the benefits and drawbacks of each, and how to find the perfect housing for your needs. This is the only guide you'll need to make your decision on where to live while you're abroad." />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Student Housing</h4>
                <p>While you stay in a foreign land, the place where you live is something you can call ‘your own’. Get a house that you can call your home with ExpertGRAD. </p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Student Accommodation Abroad </h4>
                <p>After the tuition fee, you spend the most significant amount on accommodation. And when you are not studying or hanging out with your friends, that’s where you spend your time. So, your place should be something that gives you peace of mind and an environment where you can study and all this within your budget. </p>

                <p>And the closer it is to your college or university, the better it is. This ensures you don’t waste too much time commuting. Instead, you can utilize this time to study or enjoy yourself with your friends.</p>

                <p>International students have various options for accommodation. And they may choose among these depending on their availability, student budget, and preferences. The various options available to the students are:</p>
            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Living on campus'>
                            <h4 className='innertitle'>Living on campus </h4>
                            <p>For international students, the best and cheapest option is to live as a resident on the university campus. You stay very close to your college and can be part of all the happenings at the university. Also, the average cost works out cheaper as rentals are not too high. The average cost of campus accommodation, meals included, in different countries varies as follows:</p>
                            <div className="costtab mt-4"> The USA - $800 to $900 per month</div>
                            <div className="costtab"> The UK - £400 - £600 per month</div>
                            <div className="costtab"> Australia – AUD 500 to AUD 1,000 per month</div>
                            <div className="costtab"> Canada - C$ 800 to C$ 1,000 per month</div>

                            <p>However, since this accommodation is limited, one must apply for it separately and well ahead of time to secure a slot.</p>
                        </Tab>

                        <Tab eventKey='tab-2' title='Student Apartments'>
                            <h4 className='innertitle'>Student Apartments </h4>
                            <p>If on-campus accommodation is unavailable or you want some privacy and space for yourself, you may go for student apartments. This is an off-campus accommodation for people who are enrolled in a university as students. These are usually small rooms with an attached toilet and a small kitchen. Usually, these are non-furnished and do not include meals. You have to manage both these things on your own. </p>

                            <p>The average rentals for student apartments are higher than for campus accommodations, but the rules are not as strict. Though the rent will vary from city to city, the average rentals in each of the countries are:</p>
                            <div className="costtab mt-4">	The USA - $400 to $500 per month</div>
                            <div className="costtab"> The UK - £550 to £650 per month</div>
                            <div className="costtab"> Australia – AUD 400-800 per month</div>
                            <div className="costtab"> Canada - C$ 400 to C$ 700 per month</div>
                        </Tab>

                        <Tab eventKey='tab-3' title='Homestays'>
                            <h4 className='innertitle'>Homestays</h4>
                            <p>If you want to stay off-campus and not worry about cooking your own food, you have one more option. Homestay involves staying with a family, where you get a separate room for yourself and an attached toilet. The family, along with providing some basic furniture, also takes care of your meals. </p>

                            <p> Again, the rent varies from city to city. However, the average cost of homestay in the different countries is listed below:</p>
                            <div className="costtab mt-4">	The USA - $600 to $3,000 per month</div>
                            <div className="costtab">	The UK - £800 and £1,000 per month</div>
                            <div className="costtab"> Australia – AUD 800 – AUD 2,000 per month</div>
                            <div className="costtab">	Canada - C$ 750 to C$ 950 per month</div>
                        </Tab>

                        <Tab eventKey='tab-4' title='Conclusion'>
                            <h4 className='innertitle'>Conclusion</h4>
                            <p>Whichever country you study in, you need a play to stay. However, finding suitable accommodation in an unknown country can be quite challenging, especially if it needs to be within your budget. </p>

                            <p>ExpertGRAD, through its team of local experts, helps you find suitable accommodation for you in all the major cities, crack the deal with the landlord and get the agreements done. And not just that, ExpertGRAD provides regular study during your stay.</p>
                            
                        </Tab>

                        
                    </Tabs>
                </Row>
            </Container>
            
        </>
    )
}

export default StudentHousingMain