import '../innerpage.css'
import { Container, Tabs, Tab, Row, Accordion, Col } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
const CanadaVisaMain = () => {


    return (
        <>
            <Helmet>
                <title>Student Visa Guide for the Australia: Cost, Requirements, Documents & Process | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content="Are you looking for a student visa for Australia? ExpertGRAD can help you navigate the complicated application process and get your visa approved. We'll tell you about the cost, requirements, documents, and process so you can be prepared for your studies Down Under. Read more now." />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Student Visa Guide for Australia</h4>
                <p>Leaving your country to study in a foreign land may have several surprises. ExpertGRAD can help you make this journey smooth.
                </p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>All the important information about Australian student visas</h4>
                <p> Any international student wanting to pursue education in Australia must obtain a student visa. Called Subclass 500, the visa permits you to study in any recognized educational institute. In addition, there is one more visa type in Australia. Called Subclass 590, this is a temporary guardian visa meant for the parents of students who are under 18. However, this visa does not allow working in Australia. </p>

            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Cost/Fees of student visa</h4>
                <p>The base fee for a student visa for Australia (subclass 500) is AUD 650.</p>
            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Australia Student Visa Requirements'>
                            <h4 className='innertitle'>Australia Student Visa Requirements</h4>
                            <p>A person who is not a citizen of Australia can apply for a student visa. The primary requirements are completion of 16 years of age, confirmed admission from a recognized educational institution in Australia, proficiency in the English language, enough money to pay for studying and living in Australia and no criminal history.</p>
                        </Tab>

                        <Tab eventKey='tab-2' title='Australia Student Visa'>
                            <h4 className='innertitle'>Documents Required for Australia Student Visa</h4>

                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Electronic Confirmation of Enrolment (eCoE) certificate</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>
                                    Genuine Temporary Entrant (GTE) statement
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Financial Requirements that you can fund your studies (To cover your return airfare, tuition fees and a sum of AUD 18,610 per year)</li>

                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Your English Proficiency test results</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Australian approved health insurance cover
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Verification of your criminal records
                                </li>

                            </ul>
                        </Tab>

                        <Tab eventKey='tab-3' title='Eligibility Criteria for Australia Student Visa'>
                            <h4 className='innertitle'>Eligibility Criteria for Australia Student Visa</h4>
                            <p>Given below are some of the eligibility criteria for a student visa to Australia</p>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>The person should be at least 18 years of age. For students less than 18, there are some special requirements.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Get enrolled on any Australian institute and provide evidence of that
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Have adequate health insurance</li>

                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Have enough financial health to support your stay and study
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Meet the character requirement
                                </li>

                            </ul>
                        </Tab>

                        <Tab eventKey='tab-4' title='Australia Study Visa Process'>
                            <h4 className='innertitle'>Australia Study Visa Process</h4>
                            <p>To make it easy to understand the visa process for Australia, given below is a step-by-step process:</p>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Collect information about various eligibility requirements and gather all the required documents.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> You would need documents like proof of admission, identity, financial health etc. that show you have fulfilled the visa requirements.
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Apply for the visa online</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> You will receive a notification once your application is received by the concerned authorities.
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Within the stipulated time, you will be notified about the status of your visa application.
                                </li>
                            </ul>
                        </Tab>

                        <Tab eventKey='tab-5' title='Processing time'>
                            <h4 className='innertitle'>Processing time </h4>
                            <p>The application for visa subclass 500 usually takes 4 weeks of processing time.</p>
                        </Tab>
                        <Tab eventKey='tab-6' title='Apply for a student visa with ExpertGRAD'>
                            <h4 className='innertitle'>Apply for a student visa with ExpertGRAD</h4>
                            <p>While applying for an Australian visa may be easy, ExpertGRAD ensures there are no last minute surprises or hiccups in your visa application. The combined experience of our team helps you understand the various nuances involved in the visa process. </p>
                            <p>  Our team of experts provides you with correct and timely information regarding the various requirements to be filled out to get your visa approved.
                            </p>
                        </Tab>


                    </Tabs>
                </Row>
            </Container>

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Questions </h3>
                    </Col>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1:	How can I get a student visa for Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>To get a student visa for Australia, you must first obtain a confirmed admission to one of the recognized educational institutes in Australia. Then you may apply online, furnish the required documents, pay the visa fee, and get the student visa.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2:	How much money do I need for a student visa to Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>The base fee for a student visa for Australia (subclass 500) is AUD 650.  </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3:	Is an Australian student visa easy to get?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Compared to other students getting an Australian student visa is easier. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: Does an Australian student visa get rejected?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: If a student does not fulfill the eligibility requirements, does not have proper documents, or has performed some criminal offense, the student visa may get rejected.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5:	How long does an Australian student visa take?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The processing of a student visa in Australia usually takes 4 weeks.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6:	Can I work with a student visa in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>And: The students are allowed to work up to 29 hours per week while their course is going on and full-time during their summer holidays.  </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7:	Can I get PR in Australia after studying?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: After completing your studies, you may apply for a post-study work visa. After some time, you may apply for General Skilled Migration to achieve PR.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q.8: Do I need IELTS to study in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Though most universities ask for an IELTS, some universities allow studying without it.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>Q.9:	Which state is easy to get PR in Australia?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The easiest state to get a PR in Australia is Tasmania, followed by New South Wales and South Australia. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </Container>
            </section>
        </>
    )
}

export default CanadaVisaMain