import React from 'react'
import './footer.css'

import { Col, Row, Container } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className='footer'>
                <Container>
                    <Row className='d-md-flex justify-content-center'>
                        <Col md={12}>
                            <h4>Study Support Program</h4>
                            <hr></hr>
                            <ul>
                                <li>Full Course/Module Academic Support</li>
                                <li> Preparatory  Crash Course</li>
                                <li>Online Assignment Bank</li>
                                <li> One-to-One Academic Mentorship</li>
                            </ul>

                            <h4>Academic support available for:</h4>
                            <hr></hr>
                            <ul>
                                <li>Australia</li>
                                <li> UK</li>
                                <li>USA</li>
                                <li> Canada</li>
                                <li> UAE</li>
                                <li> New Zealand</li>
                                <li> Singapore</li>
                                <li> Malaysia</li>
                                <li> India</li>
                                <li> Russia</li>
                                <li> Indonesia</li>
                                <li> Vietnam</li>
                                <li> Brazil</li>
                                <li> Thailand</li>
                                <li> Philippines</li>
                                <li> Bangladesh</li>
                                <li> Sri lanka</li>
                                <li> Ukraine</li>
                                <li> Kenya</li>
                                <li> China</li>
                                <li> Hong Kong</li>
                                <li> Jordan</li>
                                <li> Kuwait</li>
                                <li> Oman</li>
                                <li> Qatar</li>
                                <li> South Africa</li>
                                <li> Saudi Arabia</li>
                                <li> Ireland</li>
                            </ul>

                            <hr></hr>

                            <p>© Copyright 2010 - 2023 ExpertGrad Educational Services. All Rights Reserved. </p>
                        </Col>
                        {/* <Col md={3}>
                            <h4>Study Abroad</h4>
                            <ul>
                                <li><Link to={`/australia/study-in-australia`}>Study in Australia</Link></li>
                                <li><Link to={`/canada/study-in-canada`}>Study in Canada</Link></li>
                                <li><Link to={`/uk/study-in-uk`}>Study in UK</Link></li>
                                <li><Link to={`/usa/study-in-usa`}>Study in USA</Link></li>
                            </ul>

                        </Col>
                        <Col md={3}>
                            <h4>Test Preparation</h4>
                            <ul>
                                <li><Link to="/ieltscoaching">IELTS Coaching</Link></li>
                                <li><Link to="/toeflCoaching">TOEFL Coaching</Link></li>
                                <li><Link to="">PTE Coaching</Link></li>
                                <li><Link to="">Duolingo</Link></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h4>Apply for Visa</h4>
                            <ul>
                                <li><Link to={`/visa-services/visa-guidelines-for-usa`}>Visa Guidelines for  USA</Link></li>
                                <li><Link to={`/visa-services/visa-guidelines-for-canada`}>Visa Guidelines for  Canada</Link></li>
                                <li><Link to={`/visa-services/visa-guidelines-for-australia`}>Visa Guidelines for  Australia</Link></li>
                                <li><Link to={`/visa-services/visa-guidelines-for-uk`}>Visa Guidelines for  UK</Link></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h4>Moving Abroad</h4>
                            <ul>
                                <li><Link to={"/moving-abroad/student-housing"}>Student Housing</Link></li>
                                <li><Link to={"/moving-abroad/paying-for-foreign-currency-forex"}>Paying for Foreign Currency</Link></li>
                                <li><Link to={"/moving-abroad/bank-account"}>Bank Account</Link></li>
                                <li><Link to={"/moving-abroad/insurance"}>Insurance</Link></li>
                                <li><Link to={"/moving-abroad/driving-license"}>Driving Licence</Link></li>
                            </ul>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer