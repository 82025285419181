import React from 'react'
import Footer from '../../Footer/Footer'
import PteCoachingBanner from './PteCoachingBanner'
import PteCoachingMain from './PteCoachingMain'
import '../innerpage.css'

function PteCoaching() {
  return (
    <>
        <PteCoachingBanner></PteCoachingBanner>
        <PteCoachingMain></PteCoachingMain>
        <Footer></Footer>
    </>
  )
}

export default PteCoaching