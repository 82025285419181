import React from 'react'
import Footer from '../../Footer/Footer'
import OurteamBanner from './OurteamBanner'
import TeamMain from './TeamMain'


const Ourteam = () => {
  
  return (
    <>       
       <OurteamBanner></OurteamBanner>
       <TeamMain></TeamMain>
       <Footer></Footer>
    </>
  )
}

export default Ourteam