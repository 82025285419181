import React from 'react'
import Footer from '../../Footer/Footer'
import InsuranceBanner from './InsuranceBanner'
import InsuranceMain from './InsuranceMain'
import '../innerpage.css'

function Insurance() {
  return (
    <>
        <InsuranceBanner></InsuranceBanner>
        <InsuranceMain></InsuranceMain>
        <Footer></Footer>
    </>
  )
}

export default Insurance