import './counselling.css'

import { Col, Container, Row } from 'react-bootstrap'
import cp1 from '../../images/cp1.svg'
import cp2 from '../../images/cp2.svg'
import cp3 from '../../images/cp3.svg'

const Counselling = () => {
    return (
        <>
            <Container className='m-auto pb-5 pt-md-5 consp'>
                <Row className='justify-content-center'>
                    <Col md={7} className="text-center mb-3 mb-md-5">
                        <h3 className='title'>Mentoring Services</h3>
                        <p className="text-center">We provide the following services to international students studying
                            at top universities and colleges in USA, Australia, Canada, UK & UAE. </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className='cp-tab text-center'>
                            <div className='cp-img'>
                                <img src={cp1} alt="cp1"></img>
                            </div>
                            <h4 className='mb-4'> Expert Mentoring Service</h4>
                            <p>Looking for a expert mentor to help you succeed in your university studies? Our mentoring program connects students with experienced and qualified professionals who can provide support and guidance in a variety of academic areas. Whether you need help in assessments, developing research papers, or preparing for exams, our mentors can help you reach your full potential. </p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='cp-tab text-center' style={{ background: "#D9EFF3" }}>
                            <div className='cp-img' style={{ borderColor: "#57BEDF" }} >
                                <img src={cp2} alt="cp2"></img>
                            </div>
                            <h4 className='mb-4'> Academic Help</h4>
                            <p>Are you stuck with your Assignments or missed the lecture and have no clue of the topic taught in the university? Our team of experts can help you identify the learning based outcome expected through the assignment or the lecture and connect you with the right subject matter expert for smooth guidance. Our network of thousands of mentors in a wide range of fields can help you find the perfect expert to solve your academic query.
                            </p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className='cp-tab text-center' style={{ background: "#F8E9F0" }}>
                            <div className='cp-img' style={{ borderColor: "#DA3981" }}>
                                <img src={cp3} alt="cp3"></img>
                            </div>
                            <h4 className='mb-3'> Preparatory Crash Course</h4>
                            <p>Our preparatory crash course for academic research and writing is designed to help students develop the skills and knowledge they need to succeed in their International studies. The course covers a variety of topics, including how to identify and focus a research topic, how to find and evaluate sources, how to organize and synthesize information, and how to write clear and concise academic papers. 
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}
export default Counselling;