import React from 'react'
import Footer from '../../Footer/Footer'
import BankAccountBanner from './BankAccountBanner'
import BankAccountMain from './BankAccountMain'
import '../innerpage.css'



const BankAccount = () => {
  return (
    <>
        <BankAccountBanner></BankAccountBanner>
        <BankAccountMain></BankAccountMain>
        <Footer></Footer>
    </>
  )
}

export default BankAccount