import React from 'react'
import './review.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import logo from '../../../images/logo.png'
import rating from '../../../images/rating.png'
import logos from '../../../images/logos.png'
const Review = () => {
    return (
        <>
            
                <Container className='review py-3 py-md-4'>
                    <Row className='d-md-flex justify-content-center'>
                        <Col className='ps-4'>
                            {/* <img src={logo} alt="logo" height={35}></img> */}
                            <h5 className='text-right mt-0'>Top Global University For International Students</h5>
                        </Col>
                        <Col className='text-end pe-4'>
                            <img src={rating} alt="rating" height={16}></img>
                            <h6>4,5 Star Rating  (20+ Review) </h6>
                            
                        </Col>
                        <img src={logos} alt="logo" className='img-fluid mt-3'></img>
                    </Row>
                </Container>
        </>
    )
}

export default Review