import React from 'react'
import Footer from '../../Footer/Footer'
import GradingSystemBanner from './GradingSystemBanner'
import GradingSystemMain from './GradingSystemMain'
import '../innerpage.css'



const GradingSystem = () => {
  return (
    <>
        <GradingSystemBanner></GradingSystemBanner>
        <GradingSystemMain></GradingSystemMain>
        <Footer></Footer>
    </>
  )
}

export default GradingSystem