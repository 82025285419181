import React from 'react'
import Footer from '../../Footer/Footer'
import StudentHousingBanner from './StudentHousingBanner'
import StudentHousingMain from './StudentHousingMain'


const StudentHousing = () => {
  return (
    <>
       
       <StudentHousingBanner></StudentHousingBanner>
       <StudentHousingMain></StudentHousingMain>
       <Footer></Footer>
    </>
  )
}

export default StudentHousing