import React, { useState } from 'react'
import '../innerpage.css'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

import contactpic from "../../../images/contactpic.svg"
import location from "../../../images/location.png"
import mail from "../../../images/mail.png"
import callicon from "../../../images/callicon.png"
import fb from "../../../images/fb.png"
import inst from "../../../images/inst.png"
import linkdin from "../../../images/linkdin.png"
// import arrowleft from "../../../images/arrowleft.png"
import { Helmet } from 'react-helmet';

const ContactMain = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredStudyDestination, setPreferredStudyDestination] = useState("");
    const [whenToStudy, setWhenToStudy] = useState("");
    // const [nearestEGOffice, setNearestEGOffice] = useState("");
    const [fundYourEducation, setFundYourEducation] = useState("");
    const [preferredStudyLevel, setPreferredStudyLevel] = useState("");
    const [termPrivacyPolicy, setTermPrivacyPolicy] = useState("");
    // const [contactMeByPhoneEmailSMS, setContactMeByPhoneEmailSMS] = useState("");
    // const [receiveUpdates, setReceiveUpdates] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const handlePreferredStudyDestination = (event) => {
        setPreferredStudyDestination(event.target.value)
    }
    const handlesetWhenToStudy = (event) => {
        setWhenToStudy(event.target.value)
    }
    // const handlesetNearestEGOffice = (event) => {
    //     setNearestEGOffice(event.target.value)
    // }
    const handlesetFundYourEducation = (event) => {
        setFundYourEducation(event.target.value)
    }
    const handlesetPreferredStudyLevel = (event) => {
        setPreferredStudyLevel(event.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault();
        console.log('preferredStudyDestination', preferredStudyDestination);
        console.log('whenToStudy', whenToStudy);
        console.log('fundYourEducation', fundYourEducation);
        console.log('preferredStudyLevel', preferredStudyLevel);

        axios.post('//accountsfinanceassignmenthelp.com/api/studyabroad', {

            firstName: firstName,
            lastName: lastName,
            preferredStudyDestination: preferredStudyDestination,
            whenToStudy: whenToStudy,
            // nearestEGOffice: nearestEGOffice,
            fundYourEducation: fundYourEducation,
            preferredStudyLevel: preferredStudyLevel,
            email: email,
            mobile: phone,
            // termPrivacyPolicy: termPrivacyPolicy,
            termPrivacyPolicy: '1'
            // contactMeByPhoneEmailSMS: contactMeByPhoneEmailSMS,
            // receiveUpdates: receiveUpdates
        })
            .then(res => {
                toast('Thank you for your enquery');
            })

            .catch(function (err) {
                console.log(err);
            });

        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setPreferredStudyDestination('');
        setWhenToStudy('');
        // setNearestEGOffice('');
        setFundYourEducation('');
        setPreferredStudyLevel('');
        setTermPrivacyPolicy('');
        // setContactMeByPhoneEmailSMS('');
        // setReceiveUpdates('');

    }
    return (
        <>
            <Helmet>
                <title>Contact Us - Reach Out for Support and Inquiries</title>
                <meta name='description' data-react-helmet="true" content='Have questions or need assistance? Our contact information is here for you. Reach out to us for any inquiries, feedback, or support, and our team will be happy to assist you.' />
            </Helmet>
            <Container className="my-5">
                <div className="studybg my-5 text-center container">
                <h4>Contact Information</h4>
                    <p>Fill up the form and our team will get back to you within 24 hours.</p>
                    <hr></hr>
                    <address className='add-inner'>
                        <div className='d-md-flex'>
                            <p className='w-md-50 me-md-3'><img src={callicon} alt="call" width={"17"}></img> 0120-4355522 </p>
                            <p className='w-md-50 ms-md-3'><img src={mail} alt="mail" width={"17"}></img> contact@expertgrad.com</p>
                        </div>
                        <p className='w-100'><img src={location} alt="location" width={17}></img> C-22, Second floor, Sector 6, Noida, Uttar Pradesh, 201301</p>

                    </address>
                </div>
                <div className='contact'>
                    <Row>
                        <Col md={6}>
                            <div className="text-center col-md-10 m-auto">
                                <h3>Thanks for your interest </h3>
                                {/* <p className="pt-md-1">Introducing our sharp leaders, putting efforts to make academic journey a smooth sail!</p> */}

                                <ul className="social">
                                    <li>
                                        <a href="https://www.facebook.com/ExpertGrad1/" target="_blank" rel="noreferrer">
                                            <img src={fb} alt="facebook"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/expert_grad/?hl=en" target="_blank" rel="noreferrer">
                                            <img src={inst} alt="inst"></img>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://in.linkedin.com/company/expert-grad?original_referer=https%3A%2F%2Fwww.google.com%2F" target="_blank" rel="noreferrer">
                                            <img src={linkdin} alt="linkdin"></img>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                            <img src={contactpic} alt="contactpic" className='img-fluid'></img>
                        </Col>

                        <Col md={6}>
                            <div className="contact-info">
                            <form className='studyform'>
                            <Row>
                                <div className="form-group col-md-6 mb-4">
                                    <input type="text" name="firstName" className="form-control" placeholder="First Name *" onChange={event => setFirstName(event.target.value)}
                                        value={firstName} />
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <input type="text" name="lastName" className="form-control" placeholder="Last Name *" onChange={event => setLastName(event.target.value)}
                                        value={lastName} />
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <input type="email" name="email" className="form-control" placeholder="Email Id *" onChange={event => setEmail(event.target.value)}
                                        value={email} />
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <input type="text" name="phone" className="form-control" placeholder="+91-76545 56677" onChange={event => setPhone(event.target.value)}
                                        value={phone} />
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="text-muted">Your preferred study destination*</label>
                                    <select className='form-select' name="" onChange={handlePreferredStudyDestination} >
                                        <option>Please select</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Canada">Canada</option>
                                        <option value="UK">UK</option>
                                        <option value="USA">USA</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label className="text-muted">When do you plan to study?*</label>
                                    <select className='form-select' name="" onChange={handlesetWhenToStudy} >
                                        <option value="USA">Please select year</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                    </select>
                                </div>

                                {/* <div className="form-group col-md-12">
                                    <label className="text-muted">Nearest ExpertGRAD Office*</label>
                                    <input type="text" name="nearestoffice" className="form-control" placeholder="+91-76545 56677" onChange={event => handlesetNearestEGOffice(event.target.value)}
                                        value={phone} />
                                </div> */}

                                <div className="form-group col-md-12 mb-4">
                                    <label className="text-muted">How would you fund your education?*</label>
                                    <select className='form-select' name="" onChange={handlesetFundYourEducation} >
                                        <option value="">Please select</option>
                                        <option value="Education Loan">Education Loan</option>
                                        <option value="Self">Self</option>
                                        <option value="Scholarship">Scholarship</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-12 mb-4">
                                    <label className="text-muted">Preferred study level*</label>
                                    <select className='form-select' name="studyLevel" onChange={handlesetPreferredStudyLevel} >
                                        <option value="">Please select</option>
                                        <option value="Graduate">Graduate</option>
                                        <option value="Masters">Masters</option>
                                        <option value="PHD">PHD</option>
                                        <option value="Diploma or Certification">Diploma or Certification</option>
                                    </select>
                                </div>

                                <Col className='col-md-12'>
                                    <h6 id="passwordHelpBlock" className="text-muted">
                                        ExpertGRAD will not share your details with others without your permission.
                                    </h6>

                                    <div className="form-check mt-4">
                                        <input className="form-check-input" name="termPrivacyPolicy" type="checkbox" id="gridCheck1" onChange={event => setTermPrivacyPolicy(event.target.value)}
                                            value={termPrivacyPolicy} />
                                        {/* <label className="form-check-label text-muted" htmlFor="gridCheck1">I Agree to <Link to="#">Service Terms</Link>
                                        </label> */}
                                        <label className="form-check-label text-muted" htmlFor="gridCheck1">I Agree to Service Terms
                                        </label>
                                    </div>
                                    <button className="btn-yellow mt-3 mt-md-5" onClick={handleSubmit} >Submit
                                        {/* <img src={arrowleft} alt="arrowleft" height={15}></img> */}
                                    </button>

                                </Col>
                            </Row>
                        </form>

                            </div>
                        </Col>

                    </Row>
                </div>

            </Container>
            <ToastContainer />
        </>
    )
}

export default ContactMain