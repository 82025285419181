import React from 'react'
import Header from '../../Header/Header'

import '../innerpage.css'
import {Container, Col, Row } from 'react-bootstrap';

import ausguidline from '../../../images/ausguidline.png'

const PreparatoryCrashCourseBanner = () => {
  return (
    <>
        <div className='innerbanner pt-4'>
            <Header></Header>

            <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={ausguidline} alt="ausguidline" className='animated2 infinite fadeInLeftRight'></img>
                            </Col>
                            <Col md={7}>
                                <h2>Preparatory <br></br><span> Crash Course </span></h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </div>
    </>
  )
}

export default PreparatoryCrashCourseBanner