import React from 'react'
import Footer from '../../Footer/Footer'
import SmainGuidelineBanner from './SmainGuidelineBanner'
import SguideLineMain from './SguideLineMain'


const SguideLine = () => {
  return (
    <>
       
       <SmainGuidelineBanner></SmainGuidelineBanner>
       <SguideLineMain></SguideLineMain>
       <Footer></Footer>
    </>
  )
}

export default SguideLine