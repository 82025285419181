import '../innerpage.css'
import { Container, Tabs, Tab, Row, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const GradingSystemMain = () => {
    return (
        <>
            <Helmet>
                <title>Grading System - Understanding Academic Evaluation Methods</title>
                <meta name='description' data-react-helmet="true" content="Learn about the various grading systems used in education and their significance in assessing students' performance. Discover how different grading methods are applied in schools and universities worldwide." />
            </Helmet>

            <Container className='studybg my-5'>
                <h4>Grading System</h4>
                <p>Whether you study in India or anywhere in the world, grading is an inseparable aspect of education. The grading system evaluates all aspects of education, from class participation and assignments to examinations during the course. In addition, the grading system helps decide if a student is allowed to move from one year to another and be awarded a degree.</p>
                <p>So, before you embark on your educational trip to a foreign land, you must understand the grading system in your host country.</p>

            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Grading System in the USA'>
                            <h4 className='innertitle'>Grading System in the USA</h4>
                            <p>Unlike India, in the USA, students are allotted alphabetical and numerical grades based on a system of credits. </p>
                            <p>These credit hours and credits, in turn, make your overall grade. The Grade Point Average (GPA) indicates a student’s performance throughout the academic semester across courses.</p>
                            <p> The final GPA score you graduate with is much more than the average of all your previous scores. </p>
                            <p>Final GPA = Credit Hours X Letter Grade Value</p>
                            <h5>Grading Scale</h5>
                            <p>GPA scores can range from A to D, where ‘A’ means excellent and ‘D’ means the student is barely managing the course. An ‘F’ grade means the student fails to meet the academic requirements. In numerical terms, A represents 4, D represents 1, and F represents 0. </p>
                            <p>Please refer to the following table to convert this grading into the Indian equivalent.</p>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Grade</th>
                                        <th>Grade Point </th>
                                        <th>Meaning </th>
                                        <th>Indian Grading Equivalent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>A</td>
                                        <td>4</td>
                                        <td>Excellent</td>
                                        <td>90% to 100%</td>
                                    </tr>
                                    <tr>
                                        <td>B</td>
                                        <td>3</td>
                                        <td>Good</td>
                                        <td>80% to 89%</td>
                                    </tr>
                                    <tr>
                                        <td>C</td>
                                        <td>2</td>
                                        <td>Average</td>
                                        <td>70% to 79%</td>
                                    </tr>
                                    <tr>
                                        <td>D</td>
                                        <td>1</td>
                                        <td>Passing</td>
                                        <td>59% to 69%</td>
                                    </tr>

                                    <tr>
                                        <td>F</td>
                                        <td>0</td>
                                        <td>Failing</td>
                                        <td>Between 0% and 59%</td>
                                    </tr>

                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey='tab-2' title='Grading System in Canada'>
                            <h4 className='innertitle'>Grading System in Canada</h4>
                            <p className='mb-4'>Unlike the USA, Canada does not have a centralized education system. Hence, like the education system, the grading system varies from province to province. Therefore, it varies with the province and university you have enrolled on, and the course opted for.   </p>

                            <p> Numerically, the grading scale can range from 4.0 to 9.0. There are variations in the alphabetical grades, numerical scores, and percentages across provinces. A teacher decides whether an ‘A’ grade means a 4.0 or 4.3.  </p>

                            <p>Given below are two of the most common grading scales followed in Canada.</p>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Grade</th>
                                        <th>Percent </th>
                                        <th>GPA </th>
                                        <th>Grade</th>
                                        <th>Percent</th>
                                        <th>GPA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>A+</td>
                                        <td>92% - 100%</td>
                                        <td>4.33</td>
                                        <td>A+</td>
                                        <td>90% - 100%</td>
                                        <td>9.0</td>
                                    </tr>
                                    <tr>
                                        <td>A</td>
                                        <td>88% - 91%</td>
                                        <td>4.00</td>
                                        <td>A</td>
                                        <td>80% - 89%</td>
                                        <td>8.0</td>
                                    </tr>
                                    <tr>
                                        <td>A-</td>
                                        <td>85% - 87%</td>
                                        <td>3.67</td>
                                        <td>A-</td>
                                        <td>75% - 79%</td>
                                        <td>7.0</td>
                                    </tr>
                                    <tr>
                                        <td>B+</td>
                                        <td>82% - 84%</td>
                                        <td>3.33</td>
                                        <td>B+</td>
                                        <td>65% - 69%</td>
                                        <td>6.0</td>
                                    </tr>
                                    <tr>
                                        <td>B</td>
                                        <td>78% - 81%</td>
                                        <td>3.00</td>
                                        <td>B</td>
                                        <td></td>
                                        <td>5.0</td>
                                    </tr>
                                    <tr>
                                        <td>B-</td>
                                        <td>75% - 77% </td>
                                        <td>2.67</td>
                                        <td>B-</td>
                                        <td>60% - 64%</td>
                                        <td>4.0</td>
                                    </tr>
                                    <tr>
                                        <td>C</td>
                                        <td>72% - 74%</td>
                                        <td>2.33</td>
                                        <td>C+</td>
                                        <td>55% - 59%</td>
                                        <td>3.0</td>
                                    </tr>
                                    <tr>
                                        <td>C+</td>
                                        <td>68% - 71%</td>
                                        <td>2.00</td>
                                        <td>C</td>
                                        <td>50% - 54%</td>
                                        <td>2.0</td>
                                    </tr>
                                    <tr>
                                        <td>C-</td>
                                        <td>65% - 67%</td>
                                        <td>1.67</td>
                                        <td>C-</td>
                                        <td>40% - 49%</td>
                                        <td>1.0</td>
                                    </tr>

                                    <tr>
                                        <td>D</td>
                                        <td>55% - 64%</td>
                                        <td>1.00</td>
                                        <td>D</td>
                                        <td>0% to 39%</td>
                                        <td>0.0</td>
                                    </tr>

                                    <tr>
                                        <td>F</td>
                                        <td>0% - 54%</td>
                                        <td>0.00</td>
                                        <td>F</td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                </tbody>
                            </Table>

                            <p>While the grading scale varies, the teaching system is similar to the USA. Each course in a particular semester has a specific number of credit hours assigned to it. Students earn credits by attending the required credit hours and completing the course successfully.</p>

                        </Tab>
                        <Tab eventKey='tab-3' title='Grading System in the UK'>
                            <h4 className='innertitle'>Grading System in the UK</h4>
                            <p>While applying for a UG course in the UK, you have a choice between two types of degrees:</p>

                            <ul className='secpnt ps-0'>
                                <li><strong>Ordinary degree:</strong> This is the regular degree, where you can pick any combination of subjects, complete 15 credit hours in 3 years and get the degree. You need to pass all the subjects to receive the degree.</li>
                                <li><strong>Honour's degree:</strong>This is a 4-year degree where you pick a specialised subject besides some compulsory subjects and complete 20 credit hours. This provides in-depth knowledge about a subject. An honours degree has more respect and popularity than a regular degree.</li>
                            </ul>

                            <h5>Honour's degree classifications</h5>
                            <p>An honour's degree is divided into four classes according to the UK university grading system: First class, upper second, lower second, and third class. Each class has been allocated an equal percentage and grade.</p>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Classification</th>
                                        <th>Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>First-class (1st)</td>
                                        <td>70%+</td>
                                    </tr>
                                    <tr>
                                        <td>Upper second-class (2:1)</td>
                                        <td>60 - 69%</td>
                                    </tr>
                                    <tr>
                                        <td>Lower second-class (2:2)</td>
                                        <td>50 – 59%</td>
                                    </tr>
                                    <tr>
                                        <td>Third class (3rd)</td>
                                        <td>40 – 49%</td>
                                    </tr>

                                </tbody>
                            </Table>

                            <ul className='secpnt ps-0'>
                                <li><strong>First-class degree:</strong> It reflects your passion and enthusiasm in your field of study, increasing your chances of securing your ideal job or acceptance to your preferred university for further studies.</li>
                                <li><strong>Upper second-class degree:</strong> It demonstrates that you have a good command of your subject. It is a minimum eligibility criterion for universities and employers who want to hire the best. </li>

                                <li><strong>Lower second-class degree:</strong> Also called Desmond or two-two, it is the minimum grade required to be considered for a traditional graduate program or job opportunity.</li>
                                <li><strong>Third-class degree:</strong> Known as a D-grade, it indicates that you barely passed the course. Only a small percentage of graduates earn this degree. </li>
                            </ul>

                        </Tab>

                        <Tab eventKey='tab-4' title='Grading System in Australia'>
                            <p>The grading system in Australia may confuse you at first glance when compared with the international grading scale. For example, while a “D” grade in most countries means a low score, in Australia, it means a Distinction. </p>
                            <p>Most universities in Australia follow a similar grading scale system. However, some differences exist between states, institutions, and types of programs.</p>

                            <p> The basic grading scale used in Australia is as follows:</p>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Grade scale %</th>
                                        <th>GPA</th>
                                        <th>Grade</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>85% - 100%</td>
                                        <td>A+</td>
                                        <td>HD</td>
                                        <td>Hight Distinction</td>
                                    </tr>
                                    <tr>
                                        <td>75% - 84%</td>
                                        <td>A</td>
                                        <td>D</td>
                                        <td>Distinction</td>
                                    </tr>
                                    <tr>
                                        <td>65% - 74%</td>
                                        <td>B</td>
                                        <td>C</td>
                                        <td>Credit</td>
                                    </tr>
                                    <tr>
                                        <td>50% - 64%</td>
                                        <td>C</td>
                                        <td>P</td>
                                        <td>Pass </td>
                                    </tr>

                                    <tr>
                                        <td>50% - 64%</td>
                                        <td>F</td>
                                        <td>E</td>
                                        <td>Fail</td>
                                    </tr>

                                </tbody>
                            </Table>

                            <p>In the case of an Honours degree, the grading system has additional grades and grade descriptions, which are as follows:</p>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Grade scale %</th>
                                        <th>Grade</th>
                                        <th>Grade Discription</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>85% - 100%</td>
                                        <td>H1</td>
                                        <td>First class honours</td>
                                    </tr>
                                    <tr>
                                        <td>75% - 84%</td>
                                        <td>H2A</td>
                                        <td>Second class honours</td>
                                    </tr>
                                    <tr>
                                        <td>65% - 74%</td>
                                        <td>H2B</td>
                                        <td>Second class honours</td>
                                    </tr>
                                    <tr>
                                        <td>50% - 64%</td>
                                        <td>H3</td>
                                        <td>Third class honours</td>
                                    </tr>

                                    <tr>
                                        <td>50% - 64%</td>
                                        <td>P</td>
                                        <td>Pass</td>
                                    </tr>

                                    <tr>
                                        <td>49% & Lower</td>
                                        <td>P</td>
                                        <td>Fail</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <p>All states and internal territories of Australia use a similar but not identical grading system. The base structure remains the same, but the state adjusts this due to unique requirements, legislation and needs.</p>
                        </Tab>

                    </Tabs>
                </Row>
            </Container>


        </>
    )
}

export default GradingSystemMain