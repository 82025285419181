import React from 'react'
import Footer from '../../Footer/Footer'
import ToeflCoachingBanner from './ToeflCoachingBanner'
import ToeflCoachingMain from './ToeflCoachingMain'
import '../innerpage.css'



const ToeflCoaching = () => {
  return (
    <>
        <ToeflCoachingBanner></ToeflCoachingBanner>
        <ToeflCoachingMain></ToeflCoachingMain>
        <Footer></Footer>
    </>
  )
}

export default ToeflCoaching