import React from 'react'
import PolicyBanner from './PolicyBanner'
import PolicyConditions from './PolicyConditions'

const Policymain = () => {
  return (
    <>
        <PolicyBanner></PolicyBanner>
        <PolicyConditions />
    </>
  )
}

export default Policymain