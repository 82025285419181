import React from 'react'
import Footer from '../../Footer/Footer'
import AssignmentsBanner from './AssignmentsBanner'
import AssignmentsMain from './AssignmentsMain'
import '../innerpage.css'



const Assignments = () => {
  return (
    <>
        <AssignmentsBanner></AssignmentsBanner>
        <AssignmentsMain></AssignmentsMain>
        <Footer></Footer>
    </>
  )
}

export default Assignments