import React, { useState, useEffect } from "react"
import axios from "axios";
import '../innerpage.css'
import { Container, Row, Col } from 'react-bootstrap'

import BlogBanner from './BlogBanner'
import Footer from '../../Footer/Footer'

import view from '../../../images/view.png'
import watch from '../../../images/watch.png'
import dateImg from '../../../images/date.png'
import { useParams, Link } from 'react-router-dom';

const ArticalDetails = () => {
    const [myBlogDetails, setMyBlogDetails] = useState([]);
    const { title } = useParams();
    console.log(title);

    useEffect(() => {
        axios.get("//accountsfinanceassignmenthelp.com/api/blog/" + title)
            .then((res) => setMyBlogDetails(res.data));
    }, [title]);
    // console.log(myBlogDetails);

    // useEffect(() => {
    //     axios.get("//accountsfinanceassignmenthelp.com/api/blog/" + title)
    //         .then((res) => setMyBlogDetails(res.data));
    // }, [title]);

let newDate = new Date()
let date_raw = newDate.getDate();
let month_raw = newDate.getMonth() + 1;
let year = newDate.getFullYear();
var date, month
  
if (date_raw<10)  {  date ="0"+date_raw.toString()} else {  date =date_raw.toString()}
if (month_raw<10)  {  month ="0"+month_raw.toString()} else {  month =month_raw.toString()}
    // const [isMobile, setIsMobile] = useState(false);
    console.log(year+'-'+month+'-'+date);

 // Sticky Menu Area
 useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
});
       
/* Method that will fix header after a specific scrollable */
       const isSticky = (e) => {
            const header = document.querySelector('.header-section');
            const scrollTop = window.scrollY;
            scrollTop >= 550 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
        };

    const otherInformationLoop = myBlogDetails.map((value, key) => {

        return (
            <div>
                <div className="row" key={key}>

                    {
                        value.blogs.map((blogData, k) => {
                            const url = 'https://accountsfinanceassignmenthelp.com/uploads/' + blogData.blogImg;
                            const blogDate = new Date(blogData.createdAt).toLocaleString('en-us', { month: 'short', day: 'numeric', year: 'numeric' });
                            return (
                                <Col md={7} k={k}>
                                    {/* <h3>{blogData.blogViews}</h3><hr></hr> */}
                                    <div className="picsection">
                                        <img src={url} alt="blog" className='img-fluid'></img>
                                    </div>
                                    <div className='justify-content-between d-md-flex align-items-center'>
                                        {/* <div className='exp-info'>
                                                <div className='pic'>
                                                    <img src={exp} alt="exp"></img>
                                                </div>
                                                <h6 className="mb-0">Jessica jasmine 3434</h6>
                                            </div> */}
                                        <div className="d-flex mt-3">

                                            <div className="d-flex align-items-center">
                                                <img src={view} alt="graduate" height={20}></img> <p className="ms-2">{blogData.blogViews}</p>
                                            </div>
                                            <div className="d-flex ms-3 align-items-center">
                                                <img src={watch} alt="graduate" height={17}></img> <p className="ms-2">{{ year } - { month } - { date }}</p>
                                            </div>
                                            <div className="d-flex ms-3 align-items-center">
                                                <img src={dateImg} alt="graduate" height={17}></img> <p className="ms-2">{blogDate}</p>
                                            </div>
                                        </div>

                                    </div><hr></hr>


                                    <div dangerouslySetInnerHTML={{ __html: blogData.description }} />

                                </Col>
                            )
                        })
                    }
                    <Col md={5} className="header-section">
                        <h3>Related Articles</h3><hr></hr>
                        {
                            value.relatedBlogs.map((relatedBlog, i) => {
                                const urls = 'https://accountsfinanceassignmenthelp.com/uploads/' + relatedBlog.blogImg;
                                return (

                                    <div className="related-blo" i={i}>
                                        <Row>
                                            <Col md="4">
                                                <img src={urls} alt="art1" className="img-fluid"></img>
                                            </Col>
                                            <Col md="8">
                                                <Link to={`/blog/${relatedBlog.slug}`}>
                                                    <h4>{relatedBlog.blogTitle}</h4>
                                                </Link>
                                                <p dangerouslySetInnerHTML={{ __html: relatedBlog.shortDescription }} />

                                            </Col>
                                        </Row> <hr></hr>
                                    </div>
                                )
                            })
                        }
                    </Col>


                </div>
            </div>
        )
    });


    return (
        <>
            <BlogBanner></BlogBanner>
            <Container className="pt-5 details">                
                <Row>
                <div>{otherInformationLoop}</div>
                </Row>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default ArticalDetails
