import React from 'react'
import './whyexp.css'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// import p1 from '../../../images/p1.svg'
// import p2 from '../../../images/p2.svg'
import p3 from '../../../images/p3.svg'
const Whyexp = () => {
    return (
        <>
            <div className='whyexp'>
                <Container>
                    <Row>
                        <Col md={12} className="text-center mb-5">
                            <h3 className='title'>Why choose ExpertGRAD? </h3>
                            <p>ExpertGRAD helps you to achieve your dream of scoring good in academics. <br></br>We assist you through phases.</p>
                        </Col>
                        {/* <Col md={4}>
                            <div className='whytab'>
                                <div className='mb-5 pa1'>
                                    <img src={p1} alt="p1"></img>
                                </div>
                                <span style={{ marginTop: "150px" }}>Phase 1</span>
                                <h4>Pre-Studies</h4>
                                <ul>
                                    <li>Career Counselling</li>
                                    <li>Assistance in Studying Abroad</li>
                                    <li>Identification of Potential Universities</li>
                                    <li>Helping Prepare SOPs</li>
                                    <li>Preparing the Student for Interviews</li>
                                    <li>Helping the Student Obtain an Offer</li>
                                    <li>IELTS / TOEFL Test Preparation</li>
                                    <li>Preparation for Studying Abroad</li>
                                    <li>Education Loan Assistance</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='whytab whytab2'>
                                <div className='mb-5'>
                                    <img src={p2} alt="p2"></img>
                                </div>
                                <span>Phase 2</span>
                                <h4>Pre-Arrival Support and On-Arrival Support</h4>
                                <ul>
                                    <li>Flight Booking & Travel Arrangement</li>
                                    <li>Forex Services</li>
                                    <li>Assistance in Finding the Suitable Accommodation</li>
                                    <li>Insurance and Living Support</li>
                                </ul>
                            </div>
                        </Col> */}
                        <Col md={12}>
                            <div className='whytab whytab3 d-md-flex align-items-center'>
                                <div className='pa'>
                                    <img src={p3} alt="p3" className='img-fluid'></img>
                                </div>
                                <div>
                                <span>Score A<pre className='mb-0'>++</pre></span>
                                <h4>Study Support</h4>
                                <ul>
                                    <li>Pre-University Academic Research & Writing Course for International Students with Mentoring and Resources</li>
                                    <li>Coaching and mentoring on how studies and assignments are conducted abroad, including plagiarism and other aspects.</li>

                                    <li>Get personalized academic support from a dedicated mentor who will guide you through your entire course.</li>
                                    <li>Access to over 1 Million Academic Research Papers and solutions through Online Assignment Bank to help you ace your assignments.</li>

                                    <li>Don't stress about your academic work - get full course/module academic support. We'll help you score well in your course with scheduled sessions at your convenience.</li>

                                    <li>Support in obtaining good grades through guidance in preparing assignments, dissertation papers and other study-related services, including local country-specific tutors</li>
                                </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Whyexp