import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import { Row, Container, Col } from 'react-bootstrap'

import team from '../../../images/team.png'

const OurteamBanner = () => {
    return (
        <>
            <div className='innerbanner pt-4 ausbanner'>
                <Header></Header>
                <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={team} alt="team" className='animated2 infinite fadeInLeftRight teamsize'></img>
                            </Col>
                            <Col md={7}>
                                <h2>Our<span> Team</span></h2>
                                <p>Know your professionals. </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default OurteamBanner