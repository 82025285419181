import '../innerpage.css'
import { Container, Tabs, Tab, Row, Col, Accordion } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
const CanadaVisaMain = () => {


    return (
        <>
             <Helmet>
                <title>Student Visa Guide for the Canada: Cost, Requirements, Documents & Process | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content='Looking to study in Canada? Our Student Visa Guide for the Canada provides everything you need to know about the cost, requirements, documents and process involved in obtaining a student visa for Canada. Learn more today!' />
            </Helmet>

        
            <Container className='studybg my-5'>
                <h4>Student Visa Guide for Canada</h4>
                <p>Leaving your country to study abroad is very emotional and may involve several challenges. At ExpertGRAD, we ensure you don't have any unpleasant surprises during your stay abroad.
                </p>
            </Container>

            <Container className='innerTabs my-5 text-start'>                
                <h5>All important information about Canadian student visas</h5>
                <p>Any international student wanting to pursue education in Canada must obtain a student visa. Also called a study permit, it allows international students to study at Designated Learning Institutes in Canada. </p>

                <h5>Cost/Fees of student visa</h5>
                <p>The fee for a student visa for Canada is C$ 150. In addition to this, there is a biometric fee of C$ 85. </p>

                
            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Canada Student Visa Requirements '>
                            <h4 className='innertitle'>Canada Student Visa Requirements</h4>
                            <p>A person who is not a citizen of Canada can apply for a student visa. The primary requirements are completion of 16 years of age, confirmed admission from a recognized educational institution in Canada, proficiency in the English language, enough money to pay for studying and living in Canada and no criminal history.</p>
                        </Tab>

                        <Tab eventKey='tab-2' title='Canada Student Visa'>
                            <h4 className='innertitle'>Documents Required for Canada Student Visa</h4>
                            <p>To get a study permit in Canada, you need to provide the following documents:</p>

                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Proof of acceptance in a DLI in Canada</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Proof of identity – Valid passport</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> 2 Passport-size colour photographs with your name and date of birth written on the back</li>
                                <p>In addition to these, you may also be asked to furnish the following:</p>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> A letter of explanation detailing why you want to study in Canada and declaring that you understand your responsibility as a student.</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> A Quebec Acceptance Certificate (CAQ)
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Custodian Declaration (In case of minors only)
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Any other document, if required
                                </li>
                            </ul>
                        </Tab>

                        <Tab eventKey='tab-3' title='Eligibility Criteria for Student Visa'>
                            <h4 className='innertitle'>Eligibility Criteria for Canada Student Visa</h4>
                            <p>The eligibility criteria for a student visa to Canada are given below. To obtain a visa, you need to:
</p>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Prove that you have got enrolled on a DLI in Canada</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Prove that you have enough money to pay for your studying and living in Canada.
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Prove that you have no criminal record</li>
                               
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Prove that you are in good health and get a medical exam (if needed) and
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Prove to an officer that you will leave Canada when your study permit expires.
                                </li>

                            </ul>
                        </Tab>

                        <Tab eventKey='tab-4' title='Canada Study Visa Process'>
                            <h4 className='innertitle'>Canada Study Visa Process</h4>
                            <p>To make it easy to understand the visa process for Canada, given below is a step-by-step process:</p>
                            <ul className='ps-0'>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img>Get an admission letter from a designated learning institution (DLI) in Canada</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Apply for the study permit online. You may also download an application package. This includes all the forms and instructions to fill them out.
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Complete your application in all respects, submit all the required documents</li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Pay the fee for your application and keep the receipt
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Get a health check-up done by a physician among the registered panel of physicians
                                </li>
                                <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Wait for a decision on your application
                                </li>

                            </ul>
                        </Tab>

                        <Tab eventKey='tab-5' title='Processing time'>
                            <h4 className='innertitle'>Processing time </h4>
                            <p>The application for a student visa may take 12 weeks to process. And depending upon the workload on the IRCC officials, this time, maybe even more.</p>
                        </Tab>
                        <Tab eventKey='tab-6' title='Apply for a student visa with ExpertGRAD'>
                            <h4 className='innertitle'>Apply for a student visa with ExpertGRAD</h4>
                            <p>While applying for a Canadian visa may be cumbersome, ExpertGRAD can make the process smooth for you. The combined experience of our team helps you understand the various nuances involved in the visa process.
                            </p>
                            <p> Our team of experts provides you with correct and timely information regarding the various requirements to be filled out to get your visa approved.</p>
                        </Tab>


                    </Tabs>
                </Row>
            </Container>

            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Question </h3>
                    </Col>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1:	How much bank balance is required for a Canadian student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>One year of studying and living in Canada may cost a student around CAD 20,000. So, this is the bank balance one needs to maintain for a Canadian student visa.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: Does Canada give student visas easily?</Accordion.Header>
                            <Accordion.Body>
                                <p>Compared to other countries, getting a student visa in Canada is not easy. About 30% of visa applications every year get rejected.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3:	What is the maximum age for a student visa in Canada?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Though there is no age limit, the preferred age group is 18 to 35. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: How much cash can a student carry to Canada?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: There is no fixed cash limit. But while carrying more than CAD 10,000, it's better to declare it to immigration officials.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5:	Does the embassy check the bank statement?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The embassy officials ask you for 6 months' bank statement to verify your financial health. However, if required, they can, and they do verify bank statements. </p>
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6:	What do visa officers check for a student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>And: The visa officers check for properly completed forms, valid travel documents, confirmed admission proof, and proof of financial health.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        
                    </Accordion>
                </Container>
            </section>

        </>
    )
}

export default CanadaVisaMain