import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Footer from '../../Footer/Footer'


const TermsConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and condition | ExpertGRAD</title>
        <meta name='description' data-react-helmet="true" content="Our terms and conditions are designed to protect both you and us. By using our website, you agree to these terms. Please read them carefully." />
      </Helmet>
      <Container className='my-5 text-start'>
        <h4 className='innertitle'>Terms and Conditions</h4>
        <p>By using this website, you indicate acceptance of its terms and agree to abide by them. Please
read these terms of use carefully before using the website. If you have visited this website by
mistake or do not agree with the terms of use, we advise you not to use it further.</p>
        <h5>Disclaimer related to information on the website:</h5>
        <p>All the content uploaded on the website is for informational purposes only and should not be
treated as a piece of professional or legal advice. Though the content is updated regularly, we do
not accept any responsibility for any damages or losses, direct or indirect, arising from using the
website. The users are recommended to exercise their good sense and do self-verification before
acting on it.</p>
        <h5>Intellectual property rights</h5>
        <p>As the sole owner of this website, we are the legal licensee of all intellectual property and the
material published on it. Copyright laws and treaties around the world protect those works.</p>
        <p>If you are seeking information on this topic, you may download this content, take a print for your
reference, and share it with others. However, you are advised to refrain from making digital
copies of your downloaded content. You are also advised not to use any illustrations,
photographs, video or audio sequences, or graphics separately from any accompanying text. You
must not use any part of the material on our website for commercial purposes without obtaining a
license from us or our licensors.</p>
        <p>If you print off, copy or download any part of our website in breach of these terms of use, your
right to use our website will cease immediately, and you must, at our option, return or destroy
any copies of the materials you have made.</p>

        <h5>Accessing our site</h5>
        <p>At ExpertGRAD, we reserve the right to withdraw or amend our service on our website without
notice (see below). In addition, we will not be liable if, for any reason, our website is unavailable
at any time or for any period.</p>
      </Container>
      
      <Footer></Footer>
    </>
  )
}

export default TermsConditions