import React from 'react'
import Footer from '../../Footer/Footer'
import HostcountryBanner from './HostcountryBanner'
import HostcountryMain from './HostcountryMain'
import '../innerpage.css'

function Hostcountry() {
  return (
    <>
        <HostcountryBanner></HostcountryBanner>
        <HostcountryMain></HostcountryMain>
        <Footer></Footer>
    </>
  )
}

export default Hostcountry