import '../innerpage.css'
import { Container, Row, Tabs, Tab } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
function DrivinglicenseMain() {
    return (
        <>
            <Helmet>
                <title>Driving License for International Students | Expertgrad</title>
                <meta name='description' data-react-helmet="true" content='Expertgrad offers a driving license course for international students. With this course, you will be able to get a driving license in the United States. The course includes a classroom session and a driving test. You will also be given a certificate of completion. Read more here.' />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Driving License</h4>
                <p>While your primary reason for going abroad is to study, while you stay there, traveling around and visiting the county is natural. While commuting to your college or going out on weekends, you may wish to drive. However, the Indian driving license may not be valid abroad. And unless you have the proper permit and necessary information, getting on the roads is not advisable.</p>
                <p>The driving rules are different in various countries. The variations are in terms of left-hand or right-hand drive and which side of the road you drive; several things are required to pass the driving test. Therefore, checking the specific requirements of the country you're moving to is essential.</p>

            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>What is an International Driving License (IDL) or International Driving Permit?</h4>
                <p>An International Driving License (IDP), also called International Driving Permit (IDP), is a multi-language translation of your driving license recognized by several countries worldwide.
                </p>
                <p>It is important to note that it is not a driving license but a document that recognizes your home country's license for driving abroad.</p>

                <p>If you intend to get an IDP, the critical thing to note is that they can be issued only by your home country, which has issued your license. So, apply for it before you move abroad for your studies.</p>
                <p>The process of applying for an IDP is usually relatively simple. However, the process and the costs do vary from country to country. Below is a detailed guide to understanding each country's procedure and regulations.</p>

            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='DL for students in the USA'>
                            <h4 className='innertitle'>Driving license for international students in the USA</h4>
                            <p><strong>Process:</strong> The process to get an international driver's license in the USA differs from state to state. It depends on which state you'll be driving in. The best idea is to find your state's Department of Motor Vehicles (DMV), Registry of Motor Vehicles (RMV), or your ExpertGRAD consultant to determine specific requirements.</p>

                            <p>As per the rule, you can apply for your license only after your Student and Exchange Visitor Information System (SEVIS) record is active, which takes about ten days. Hence, waiting two weeks after arrival is advised before applying for your license, or your application may get automatically rejected.</p>

                            <p><strong>Document Required:</strong> To apply for an IDP in the USA, you would require the following:</p>
                            <ul className='secpnt ps-0'>
                                <li>I-20 form</li>
                                <li>Your unexpired passport with required US entry markings</li>
                                <li>A utility bill or bank statement showing your US address</li>
                                <li>Your Social Security Number or a letter from Social Security stating ineligibility</li>
                                <li>Your school enrolment form (DL-1/93)</li>
                                <li>A US visa with supporting documents, all valid for at least 160 days</li>
                                <li>I-94 form</li>
                                <li>Money to pay any fees</li>
                            </ul>

                            <p><strong>How to Apply:</strong> To get your IDP for the USA, you first need to prove your status as an international student living in the US. The process can be challenging, but with the proper documentation and guidance from ExpertGRAD, you'll be ready to get on the road soon.</p>

                            <p><strong>The process of applying for IDP in the US is as follows:</strong></p>

                            <ul className='secpnt ps-0'>
                                <li>Visit your nearest DMV and apply for IDP. Make sure to reach there a few minutes early to avoid long queues.</li>
                                <li>Take a written test and driver's exam.</li>
                                <li>Register your vehicle</li>
                                <li>If you wear glasses or contact lenses, carry them with you, as you may be asked to take a vision test. </li>
                                <li>After you take the written test, DMV may issue you a temporary driving permit, which would allow you to drive with some restrictions. It is advisable to practice with a local driving expert and get used to traffic rules.</li>
                            </ul>
                            <p><strong>Driving Rules:</strong> Some basic driving rules to be followed in the USA are:</p>

                            <ul className='secpnt ps-0'>
                                <li>Always drive on the right side of the road. </li>
                                <li>White lines separate lanes of traffic traveling in the same direction. Broken lines mean you may change lanes when it is safe. Solid white lines indicate staying in your lane. </li>
                                <li>Yellow lines separate traffic moving in opposing directions. Hashed or broken yellow lines mean you can carefully pass, paying attention to oncoming traffic. Never cross a solid yellow line (or double yellow line). </li>
                                <li>Use the left lane to overtake other cars traveling in your direction. Do not overtake in the right lane unless necessary. If you are driving in the far left-hand lane and someone would like to pass you, move over to the right lane when it is safe and allow them to pass.  </li>

                                <li>Always give way to pedestrians. Come to a complete stop whenever you see pedestrians trying to cross the street. </li>
                                <li>Never pass a school bus if it has flashing red lights. Wait until the lights have stopped. Be aware of bicyclists. They often share the road with cars and are supposed to follow general traffic laws. </li>
                                <li>Pull over or stop at an intersection to allow an emergency vehicle to pass you if you see one traveling with lights flashing and/or a siren running. </li>
                                <li>Do not overuse your car horn. They are meant exclusively for emergencies or for when you feel unsafe. </li>
                            </ul>

                        </Tab>
                        <Tab eventKey='tab-2' title='DL for students in Canada'>
                            <h4 className='innertitle'>Driving license for international students in Canada</h4>
                            <p className='mb-4'><strong>Process:</strong> The process of applying for a driving license as an international student in Canada depends on where you're from and which province you'll be driving in.  </p>

                            <ul className='secpnt ps-0'>
                                <li>If you're enrolled full-time at a Designated Learning Institution (DLI) in British Columbia, you may continue to use your license as long as it is current and valid. Else, you get 90 days to switch it to a British Columbia license, which you can do through the ICBC. </li>

                                <li>Ontario allows international students with a valid foreign license to drive for 60 days after arriving there. After this, they must exchange their license for an Ontario license or apply for a new license.  </li>

                                <li>In Alberta, International students with a valid foreign license and an IDP can drive for one year before obtaining an Alberta license. But if the student leaves and returns, the one-year period starts over. </li>

                                <li>In Quebec, international students and trainees can indefinitely use their driving licenses from their home country.  </li>

                                <li>A small piece of advice, even if you don't need a new license to drive in Canada, it is advisable to obtain an IDP if your license is not in French or English.  </li>
                            </ul>

                            <p><strong>Document Required:</strong></p>
                            <ul className='secpnt ps-0'>
                                <li>A completed and signed CAA IDP Application Form </li>

                                <li>A photocopy (front and back) of your valid Driver's License  </li>
                                <li>Two identical passport-type photographs (signed by you)</li>
                            </ul>

                            <p><strong>How to Apply: </strong>If you don't have a driving license but want one, you'll need to apply and take a test through your province. The process is very simple. You may visit a Canadian Automobile Association store and do the necessary formalities like:</p>

                            <ul className='secpnt ps-0'>
                                <li>Fill out the IDP application form. </li>
                                <li>Attach a copy of your valid home country driving license. </li>
                                <li>Fill out your credit card information to pay for the IDP fee.  </li>
                                <li>Wait for 30 days until your IDP hard copy is delivered. The digital copy can be received within 2 hours.</li>
                            </ul>

                            <p><strong>Driving Rules:</strong> The driving rules in Canada are similar to those in the USA. Vehicles still drive on the right-hand side of the road, but speed limits are posted in metric units.</p>
                            <p>Given below are some unique driving rules applicable in Canada:</p>

                            <ul className='secpnt ps-0'>
                                <li>For children under 40 lbs, the car seat is mandatory </li>
                                <li>Smoking in cars where minors are present is prohibited</li>
                                <li>Cell phones must be used in hands-free mode while driving </li>
                                <li>In Montreal, right-hand turns are not permitted during red lights</li>
                            </ul>
                        </Tab>
                        <Tab eventKey='tab-3' title='DL for students in Australia'>
                            <h4 className='innertitle'>Driving license for international students in Australia</h4>
                            <p><strong>Process:</strong> If you already have a current and valid driving license from your home country, you can use it to drive in Australia if the license is written in English. Otherwise, you must apply for an IDP through your home country or have your license translated into English by a translation service certified by NAATI.</p>
                            <p>However, if you don't have a driving license but want to drive in Australia, you must apply through your state or territory motor registry. Given below is a list of the concerned transport authorities.
                            </p>

                            <ul className='secpnt ps-0'>
                                <li>Australian Capital Territory - Road Transport Authority</li>
                                <li>New South Wales - Roads and Maritime Services</li>
                                <li>Northern Territory - Department of Transport</li>
                                <li>Queensland - Department of Transport and Main Roads</li>
                                <li>South Australia - Department of Planning, Transport and Infrastructure </li>
                                <li>Tasmania - Department of Infrastructure, Energy and Resources</li>
                                <li>Victoria - VicRoads</li>
                                <li>Western Australia - Department of Transport</li>
                            </ul>

                            <h5>Document Required</h5>

                            <p><strong>How to Apply:</strong></p>
                            <h5>Driving Rules</h5>

                            <ul className='secpnt ps-0'>
                                <li>Australians drive on the left side of two-way roads.</li>
                                <li>All drivers and passengers in the car must wear seat belts.</li>
                                <li>Children under seven must be in a child restraint right for the child's size and weight.</li>
                                <li>Speed limits are strictly enforced in Australia. So, never violate them.  </li>
                                <li>You cannot use a handheld mobile phone when driving.  </li>
                                <li>Where the center line marking on the road is a single broken line, vehicles may cross the line to overtake when it is safe to do so. If the center marking has two lines, you must not overtake if the line closest to your vehicle is unbroken. </li>
                                <li>Where arrows are painted on the road, you must drive in that direction only.</li>
                            </ul>
                        </Tab>

                        <Tab eventKey='tab-4' title='DL for students in the UK'>
                            <h4 className='innertitle'>Driving license for international students in the UK</h4>
                            <p><strong>Process:</strong> Driving licenses are issued by the Driver and Vehicle Licensing Authority (DVLA) in England, Scotland and Wales and in Northern Ireland by the Driver and Vehicle Agency Northern Ireland.</p>

                            <p>If you already have a driving license from outside the UK, you may use it to drive in the UK. Licenses issued in an EU or EEA country can be used in the UK for as long as the license is valid.</p>

                            <ul className='secpnt ps-0'>
                                <li>If you have a license issued in a "designated country" or an IDP, you can drive in the UK for up to 12 months. After this, you must apply to exchange it for a GB license within five years of becoming a resident.</li>
                                <li>If you don't have a license from a designated country or don't have any license, you need to apply for a provisional license, take a driving test and apply for a full license upon passing. </li>
                                <li>In Northern Ireland, those who do not hold a license must have been studying there for at least six months before taking a driving test or applying for a full license.</li>
                                <li>The driving test in the UK consists of both a practical driving assessment and a theory test covering your knowledge of the UK Highway Code.</li>
                            </ul>

                            <p><strong>Document Required:</strong></p>

                            <ul className='secpnt ps-0'>
                                <li>A valid driving license from the home country</li>
                                <li>A copy of passport for identification </li>
                                <li>Contact details</li>
                                <li>Your address for the past three years</li>
                            </ul>

                            <p><strong>How to Apply</strong></p>

                            <h5>Driving Rules:</h5>

                            <ul className='secpnt ps-0'>
                                <li>Always drive on the left-hand side of the road</li>
                                <li>Do not use and block the middle lane if the inside lane is clear</li>
                                <li>When approaching a roundabout, give priority to traffic coming from the right unless otherwise indicated</li>
                                <li>All traffic signals and road signs must be obeyed at all times</li>
                                <li>All vehicles must give way to emergency services vehicles in a safe way</li>
                                <li>The use of your hire car horn is not permitted in built-up areas from 23:30 to 07:00 hours EVERY DAY</li>
                                <li>It's illegal to talk on a mobile phone while driving in the UK. If you need to make a call, find a safe place and pull over first</li>
                                <li>You can be fined up to £500 if you don't wear a seat belt when you are supposed to</li>
                            </ul>


                            <h5>Conclusion:</h5>
                            <p>If you plan to drive abroad, you need an IDP. The process of applying for IDP varies from country to country. So, if you require any information or details, you may contact ExpertGRAD.</p>
                        </Tab>

                    </Tabs>
                </Row>
            </Container>

            
        </>
    )
}

export default DrivinglicenseMain