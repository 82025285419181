import React from 'react'
import Footer from '../../Footer/Footer'
import IeltsCoachingBanner from './IeltsCoachingBanner'
import IeltsCoachingMain from './IeltsCoachingMain'
import '../innerpage.css'

function IeltsCoaching() {
  return (
    <>
         <IeltsCoachingBanner></IeltsCoachingBanner>
        <IeltsCoachingMain></IeltsCoachingMain>
        <Footer></Footer>
    </>
  )
}

export default IeltsCoaching