import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import {Row, Container, Col} from 'react-bootstrap'

import ukbanner from '../../../images/ukbanner.png'

const UkBanner = () => {
  return (
    <>
        <div className='innerbanner pt-4 ukbanner'>
                <Header></Header>
                <div className='container-fluid'>
                <Container>
                <Row className='d-flex justify-content-center align-items-center'>                    
                    <Col md={5} className="bannerlg">
                        <img src={ukbanner} alt="ukbanner" className='animated2 infinite fadeInLeftRight'></img>                        
                    </Col>
                    <Col md={7}>
                            <h2>Study in <span>UK</span></h2>
                            <p>Enroll for a lifetime of knowledge experience in the best UK universities. </p>
                    </Col>
                </Row>
            </Container>
                </div>
            </div>
    </>
  )
}

export default UkBanner