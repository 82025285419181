import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import { Row, Container, Col } from 'react-bootstrap'

import testimonialsbanner from '../../../images/testimonialsbanner.svg'

const TestimonialsBanner = () => {
    return (
        <>
            <div className='innerbanner pt-md-4 pt-3'>
                <Header></Header>
                <div className='container-fluid'>
                    <Container>
                        <Row className='d-flex justify-content-center align-items-center'>
                            <Col md={5}>
                                <img src={testimonialsbanner} alt="ausguidline" className='animated2 infinite fadeInLeftRight'
                                style={{maxWidth:"66%"}}></img>
                            </Col>
                            <Col md={7}>
                                <h2>Student <span> Testimonials</span></h2>
                                <p>Read what our students say.  </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default TestimonialsBanner