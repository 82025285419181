import React from 'react'
import Footer from '../../Footer/Footer'
import DuolingoBanner from './DuolingoBanner'
import DuolingoMain from './DuolingoMain'
import '../innerpage.css'

function Duolingo() {
  return (
    <>
        <DuolingoBanner></DuolingoBanner>
        <DuolingoMain></DuolingoMain>
        <Footer></Footer>
    </>
  )
}

export default Duolingo