import React from 'react'
import OnlineAssignmentBankBanner from './OnlineAssignmentBankBanner'
import OnlineAssignmentBankMain from './OnlineAssignmentBankMain'
import Footer from '../../Footer/Footer'
import '../innerpage.css'

const OnlineAssignmentBank = () => {
  return (
    <>
        <OnlineAssignmentBankBanner />
        <OnlineAssignmentBankMain />
        <Footer />
    </>
  )
}

export default OnlineAssignmentBank





