import React from 'react'
import Footer from '../../Footer/Footer'
import UsaGuidlineBanner from './UsaGuidlineBanner'
import UsagidelineMain from './UsagidelineMain'


const UsaVisaguideline = () => {
  return (
    <>
       
       <UsaGuidlineBanner></UsaGuidlineBanner>
       <UsagidelineMain></UsagidelineMain>
       <Footer></Footer>
    </>
  )
}

export default UsaVisaguideline