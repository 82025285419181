import '../innerpage.css'
import { Container, Tabs, Tab, Row, Col, Accordion } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'

const SguideLineMain = () => {
    return (
        <>  
            <Container className='studybg my-5'>
                <h4>Student Visa Guide for the USA</h4>
                <p>Want to study in the USA but don't know how to go about it? ExpertGRAD can help you. Read along and get all your queries solved about student visa for the USA. </p>
            </Container>


            <Container className='my-5'>
                <h4>Student VISA Guidelines</h4>
                <p>To ensure getting a student’s visa, one must follow the following guidelines: </p>
                <ul className='ps-0' style={{ textAlign: "left" }}>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>You can only work limited hours (usually 20 hours per week) in campus jobs. </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>You must attend all your classes (unless there is an emergency).</li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>If you have a scholarship, you have to maintain a specific GPA. </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>You cannot stay after your Visa expires.</li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img>You cannot apply for permanent residence under a student visa. </li>
                </ul>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>How to get a student's Visa?:</h4>
                <p>The process for getting a student visa is as follows:  </p>

                <ul className='ps-0'>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Apply for a study program.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Make an appointment at the embassy or consulate.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Gather your documents.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Submit the visa payment.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Attend your student visa interview.
                    </li>

                </ul>
            </Container>

            <Container className='innerTabs'>
                <Row>
                    <Tabs justify variant='pills' defaultActiveKey='tab-1' className='mb-1 p-0'>
                        <Tab eventKey='tab-1' title='Student Visa Requirements: '>
                            <h4 className='innertitle'>You will need the following documents for your student visa application: </h4>

                            <ul className='ps-0'>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Proof of admission: </strong>
                                    The first requirement is a confirmed admission letter or enrollment records to show that you have been accepted to study at a US university.

                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Valid Passport: </strong>You must have a valid passport with a validity of at least six months when you apply for your Visa. If your passport is about to expire soon, apply for a fresh one immediately.

                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Student Visa application form:</strong>Fill in complete details in the application form. The student visa form can be obtained from the US embassy/consulate.

                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Passport Size Pictures: </strong>Recent passport size photographs. The exact number of pictures varies from country to country.


                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Financial Papers: </strong>
                                    Proof that you can financially support yourself during your education. If your family supports you, provide financial statements from your family members. If you have a scholarship, you need to attach proof of that.
                                </li>

                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Civil documents: </strong>
                                    You must submit your birth certificate, marriage certificate (if you are married), and CV.
                                    International student health insurance: For studying in the USA, you would be required to purchase a health insurance plan that covers the entire period of your studies.

                                </li>

                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Previous university records</strong>
                                    If you have attended a university before applying for your foreign study program, don't forget to submit your university records for the student visa application.

                                </li>
                            </ul>                           
                        </Tab>


                        <Tab eventKey='tab-2' title='Common Reasons for Student Visa Denial: '>
                            <h4 className='innertitle'>The embassy may deny your student visa for any of the following reasons: </h4>

                            <ul className='ps-0'>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img>You do not have confirmed admission to a US university
                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img>The visa application is not complete in all respects.
                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img>The passport is not valid for the student visa
                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img>There is no proper proof of financial background
                                </li>
                                <li>
                                    <img src={liarrow} alt="right-arrow" className='me-3'></img>There is some criminal record against your name.
                                </li>

                            </ul>                           
                        </Tab>

                    </Tabs>
                </Row>
            </Container>
            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequency Ask Questions </h3>
                    </Col>

                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: How many years of visa does the US give students?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: An F-1 visa is valid for a maximum of 5 years. </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: Can I get a US student visa easily?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: For Indian students, getting a student visa is not difficult, provided you meet all requirements and complete all paperwork within due time.  </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: How much money is required for a student visa in America?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A student visa costs about $500. This includes the SEVIS fee ($350) and the DS-160 form fees ($160). </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: How many years is an F1 visa valid for the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: An F-1 visa is valid for a maximum of 5 years.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5: Can I take my wife to the USA on a student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The US government allows bringing your spouse and children under 21 to live with you. However, you need to prove your relationship with them and apply for their visa separately.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6: How many types of student visas are there in the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: There are 3 different types of student visas in the USA – F, J, and M. They vary according to the course chosen and the duration of the course. </p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7: Can a student visa get a green card?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Yes, converting an F-1 visa into a green card is possible. This can be done in several ways, including self-petitioning as someone of extraordinary ability. Your employer can also request to grant you permanent citizenship. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Q.8: Is a medical test required for a US student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A medical test performed by a medical doctor authorized by US Embassy is compulsory before getting a US visa.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        
                    </Accordion>
                </Container>
            </section>
        </>
    )
}

export default SguideLineMain