import React from 'react'
import { Container } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'

const OnlineAssignmentBankMain = () => {
    return (
        <>
            <Helmet>
                <title>Online Assignment Bank - Sample Solution Library</title>
                <meta name='description' data-react-helmet="true" content='Online Assignment Bank is your one-stop destination for an extensive repository of learning materials designed to empower university students at every level of their educational journey.' />
            </Helmet>
            <Container className='studybg my-5'>
                <p>Online Assignment Bank is your one-stop destination for an extensive repository of learning
                    materials designed to empower university students at every level of their educational journey.
                    We understand that learning is a lifelong process, and we&#39;re committed to making it accessible,
                    engaging, and effective for all learners. Whether you&#39;re a sophomore seeking to improve your
                    grades, or a scholar working on your end-of-the-year thesis, you&#39;ll find what you need right here.</p>
            </Container>

            <Container className='studymain mb-5'>
                <h4 className='innertitle'>Peer-Reviewed and Thoroughly Evaluated Learning Resources</h4>
                <p>When working on an academic assignment, you need to refer to a wide range of academic
                    resources to support your research and writing. The types of resources you use will depend on
                    your specific topic and the requirements of your assignment.
                </p>
                <p>At Online Assignment Bank, you can gain FREE access to more than 2 million learning
                    materials in the form of:</p>

                <ul className='ps-0'>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img><strong>Journal Articles: </strong> Browse through peer-reviewed and reliable scholarly articles to get the
                        latest research and findings on a specific topic. </li>

                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Research Papers: </strong> Download thousands of academic papers and conference papers that
                        provide detailed research and analysis on a range of subjects.
                    </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Dissertations and Theses:</strong> Pore over graduate theses and doctoral dissertations written
                        through extensive research and gain deeper understanding of advanced topics.</li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Solved Assignment Papers:</strong> Use these to understand how to structure and approach
                        college writing tasks. Learn with examples of well-organized work, proper formatting,
                        and citation styles.
                    </li>
                    <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Interviews and Surveys:</strong> You can cite interviews and surveys as primary sources and
                        add depth and originality to your writing tasks.
                    </li>
                    <li><img src={liarrow} alt='right-arrow' className='me-3'></img><strong>Literature Reviews:</strong>
                        Get an overview of a subject by inspecting review articles in journals
                        that summarize and synthesize existing research on a topic.</li>
                </ul>

                <p>Each learning material is carefully evaluated for their credibility, relevance, and quality to ensure
                    that you get the best help for writing your college research papers. We recommend that you
                    always cite your sources properly in the required citation style (e.g., APA, MLA, Chicago) to give
                    credit to the original authors and avoid plagiarism. Your institution or instructor may have
                    specific guidelines for the types of sources they prefer for assignments, so it&#39;s a good idea to
                    check those as well.</p>

                <h4 className='innertitle mt-5'>Dive Into a World of Knowledge and Expand Your Horizons</h4>
                <p>Online Assignment Bank is committed to providing high-quality, accurate, and up-to-date
                    learning materials to help students finish their college assignments on time. All the content is
                    thoroughly reviewed and curated to meet the highest educational standards.
                    We believe that learning should be accessible to all. Hence, our materials are designed to
                    accommodate diverse learning styles, and many are available in various formats to cater to
                    individual preferences.</p>
                <p>Each academic material is created by subject matter experts, experienced educators, and
                    industry professionals, ensuring that you receive the most relevant and reliable information.</p>
                <p>Join the growing community of learners who have discovered the benefits of Online Assignment
                    Bank. Enhance your educational experience, improve your grades, and unlock your full
                    potential. Our learning materials are here to support your academic and personal growth.</p>

            </Container>


        </>
    )
}

export default OnlineAssignmentBankMain