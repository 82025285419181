import '../innerpage.css'
import { Container, Row, Tabs, Tab, Table, Accordion, Col } from 'react-bootstrap'

import liarrow from '../../../images/liarrow.svg'
import { Helmet } from 'react-helmet'
const UsagidelineMain = () => {


    return (
        <>
            <Helmet>
                <title>Student Visa Guide for the USA: Types, Cost, Requirements, Documents & Process | ExpertGRAD</title>
                <meta name='description' data-react-helmet="true" content="Looking for a student visa for the USA? ExpertGRAD has got you covered! We'll tell you everything you need to know about the different types of student visas the cost, the requirements, the documents you'll need, and the application process. So what are you waiting for? Get started today!
" />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>Student Visa Guide for the USA</h4>
                <p>Want to study in the USA but don't know how to go about it? ExpertGRAD can help you. Read along and get all your queries solved about student visa for the USA.</p>

            </Container>
            <Container className='studymain my-5 text-start'>
                <h4 className='innertitle'>All important information about the USA student visa</h4>
                
                <p>Generally, a citizen of a foreign country who wishes to study in the United States must first obtain a student visa. Your course of study and the type of school you plan to attend determine whether you need an F visa or an M visa. </p>
                <h4 className='innertitle'>Types of student visas for the USA</h4>
                <p>The USA offers various types of visas to students depending upon the course and its duration. The US government offers three types of student visas – F1, J1, and M.  </p>

                <ul className='ps-0'>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>For international students enrolled on a full-time program (even if short-term) in a registered US institute, one requires an F1-type visa.
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>The J1 visa is the US exchange visa for non-immigrant students pursuing an exchange course. 
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>Meet an ExpertGRAD expert and understand the documents required. 
                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>The M visa allows vocational and non-academic students to pursue education in the USA. 

                    </li>
                    <li>
                        <img src={liarrow} alt="right-arrow" className='me-3'></img>In addition to these types, students enrolling on a short recreational course, which does not credit for a university degree or academic certificate, may apply for a visitor (B) visa.
                    </li>

                </ul>

                <p>Persons holding a visitor visa or someone entering the USA as a part of the Visa Waiver Program (VWP) are not allowed to take admission to a degree or academic certificate course.</p>

                <p><strong>The details of the courses and the visa required are tabulated below.</strong></p>

                <Table className="table table-striped table-bordered table-hover table">
                    <thead>
                        <tr>
                            <th>To enter the United States to attend:	</th>
                            <th>Visa category: </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> University or college </td>
                            <td> F</td>
                        </tr>
                        <tr>
                            <td>High School	</td>
                            <td>F</td>
                        </tr>
                        <tr>
                            <td>Private elementary school	</td>
                            <td>F</td>
                        </tr>
                        <tr>
                            <td>Seminary	</td>
                            <td>F</td>
                        </tr>

                        <tr>
                            <td>Conservatory</td>
                            <td>F</td>
                        </tr>
                        <tr>
                            <td>Other academic institutions, including language course	</td>
                            <td>F</td>
                        </tr>
                        <tr>
                            <td>Vocational or other recognized non-academic institution, other than a language course	</td>
                            <td>M</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
           
            <Container className='innerTabs'>
                <Row>
                <Tabs justify variant='pills' defaultActiveKey='tab-2' className='mb-1 p-0'>

                    <Tab eventKey='tab-2' title=' Cost/Fees of student visa'>
                        <h4 className='innertitle'>Cost/Fees of student visa</h4>
                        <p>While the types may differ, the fee is $160 for any type of student visa. In addition to the visa fees, a student is required to pay an additional amount towards the SEVIS (Student and Exchange Visitor Information System).
                        </p>
                        <p>The SEVIS fee for type F and type M is $350, and for type J, the amount is $220.</p>
                    </Tab>
                    <Tab eventKey='tab-3' title='US student visa requirements'>
                        <h4 className='innertitle'>US student visa requirements</h4>
                        <p><strong>A student applying for a visa to the USA would need to meet the following requirements</strong></p>

                        <ul className='ps-0'>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Passport with a validity date of a minimum of six months beyond your period of stay.</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Confirmation page of DS-160 (Online non-immigrant visa application form)</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Certificate of Eligibility issued by an accredited US college or university (Form I-20)</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Payment of application fees for SEVIS</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Recent passport-size photograph.</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Any other requirement, if required by a particular university
                            </li>
                        </ul>
                    </Tab>
                    <Tab eventKey='tab-4' title='Documents Required for student Visa '>
                        <h4 className='innertitle'>Documents required for USA student Visa</h4>
                        
                        <ul className='ps-0'>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Proof of admission: </strong>
                                The first requirement is a confirmed admission letter or enrollment records to show that you have been accepted to study at a US university.</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Valid Passport: </strong> You must have a valid passport with a validity of at least six months beyond your planned period of stay.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Student Visa application form:</strong> Fill in complete details in the application form. The student visa form can be obtained from the US embassy/consulate.</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Passport Size Photos:</strong> Recent passport-size photographs. The exact number of pictures varies from country to country.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Financial Papers: </strong>
                            Proof that you can financially support yourself during your education. If your family supports you, provide financial statements from your family members. If you have a scholarship, you need to attach proof of that.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Civil documents:</strong> You must submit your birth certificate, marriage certificate (if you are married), and CV.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>International student health insurance:</strong> For studying in the USA, you would require a health insurance plan that covers the entire period of your studies.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Previous university records:</strong> If you have attended a university before applying for your foreign study program, don't forget to submit your university records for the student visa application.
                            </li>
                        </ul>
                    </Tab>
                    <Tab eventKey='tab-5' title='Eligibility criteria for USA student Visa'>
                        <h4 className='innertitle'>Eligibility criteria for USA student Visa</h4>
                        <p><strong>To apply for a student visa to the USA, you must be: </strong></p>

                        <ul className='ps-0'>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> At least 18 years of age
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> Currently in legal status and enrolled as a student 
                            </li>
                        </ul>
                    </Tab>
                    <Tab eventKey='tab-6' title='USA Study Visa Process'>
                        <h4 className='innertitle'>USA Study Visa Process</h4>
                        <p><strong>Before you can apply for a US visa, you need to get the following things in place</strong></p>

                        <ul className='ps-0'>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Take the general exam:  </strong>
                                This is to prove your skills and qualifications for the course you plan to take in the USA. This includes exams like GMAT/GRE/SAT/LSAT.</li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Score well on language proficiency tests: </strong> Since the medium of teaching in the USA is English, international students need to prove their proficiency by scoring high in language proficiency tests like IELTS, TOEFL, or PTE.

                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Apply to SEVP-approved institutes:</strong> A part of the US National Security Investigations Division, the Student and Exchange Visitor Program (SEVP) acts as a bridge between the government and non-immigrants coming to the US as students. Only a SEVP-approved institute can enrol international students. The document issued by a SEVP-approved institute is a mandatory requirement for a US visa. These institutes ensure that your degree is recognized by everyone across the world. </li>

                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Choose an Educational Foundation: </strong>
                                You need to select a course and search through universities that match your career goals. The US does not have a centralized university application system; hence you need to apply separately to each of your target universities and meet their requirements.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Send your confirmation to the university:</strong> Once you get an acceptance letter from the university, you need to send your acceptance. In some cases, you may be required to pay a part or total fee for the academic year. This needs to be done before the deadline mentioned in the acceptance letter.
                            </li>
                            <li><img src={liarrow} alt="right-arrow" className='me-3'></img> <strong>Pay the SEVIS Fee: </strong> Visit the US Immigration and Customs Enforcement (ICE) SEVP website, enter the required information exactly as it appears on your I-20 and DS-2019 application form and pay the prescribed fee. This fee must be paid at least 3 days before submitting the visa application.
                            </li>

                        </ul>
                    </Tab>

                    <Tab eventKey='tab-7' title='Processing time'>
                        <h4 className='innertitle'>Processing time</h4>
                        <p>The US consulate takes about 3-5 weeks to process a visa application. Once approved, the consulate arranges for the delivery, which may take another 2 working days. </p>

                    </Tab>

                    <Tab eventKey='tab-8' title='Visa with ExpertGRAD'>
                        <h4 className='innertitle'>Apply for a student visa with ExpertGRAD</h4>
                        <p>While applying for a US visa may be cumbersome, ExpertGRAD can make the process smooth for you. The combined experience of our team helps you understand the various nuances involved in the visa process.  </p>

                        <p>
                            Our team of experts provides you with correct and timely information regarding the various requirements to be filled out to get your visa approved.
                        </p>
                    </Tab>
                </Tabs>
            </Row>
            </Container>


            <section className='main-div faq'>
                <Container>
                    <Col md={12} className="text-center mb-md-5 mb-3">
                        <h3 className='title'>Frequently Asked Question </h3>
                    </Col>


                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Q.1: How many years of visa does the US give students?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: An F-1 visa is valid for a maximum of 5 years.</p>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Q.2: Can I get a US student visa easily?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: For Indian students, getting a student visa is not difficult, provided you meet all requirements and complete all paperwork within due time. </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Q.3: How much money is required for a student visa in America?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A student visa costs about $500. This includes the SEVIS fee ($350) and the DS-160 form fees ($160).</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Q.4: Can I take my wife to the USA on a student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: The US government allows bringing your spouse and children under 21 to live with you. However, you need to prove your relationship with them and apply for their visa separately.</p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Q.5: How many types of student visas are there in the USA?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: There are 3 different types of student visas in the USA – F, J, and M. They vary according to the course chosen and the duration of the course.</p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Q.6: Can a student visa get a green card?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: Yes, converting an F-1 visa into a green card is possible. This can be done in several ways, including self-petitioning as someone of extraordinary ability. Your employer can also request to grant you permanent citizenship.</p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Q.7: Is a medical test required for a US student visa?</Accordion.Header>
                            <Accordion.Body>
                                <p>Ans: A medical test performed by a medical doctor authorized by US Embassy is compulsory before getting a US visa.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        
                    </Accordion>
                </Container>
            </section>

        </>
    )
}

export default UsagidelineMain