import React from 'react'
import Footer from '../../Footer/Footer'
import BlogBanner from './BlogBanner'
import Latestartical from './Latestartical'
import '../innerpage.css'



const Blog = () => {
  return (
    <>
        <BlogBanner></BlogBanner>
        <Latestartical></Latestartical>
        <Footer></Footer>
    </>
  )
}

export default Blog