import React from 'react'
import Footer from '../../Footer/Footer'
import TestimonialsBanner from './TestimonialsBanner'
import TestimonialsMain from './TestimonialsMain'


const Testimonials = () => {
  return (
    <>       
       <TestimonialsBanner></TestimonialsBanner>
       <TestimonialsMain></TestimonialsMain>
       <Footer></Footer>
    </>
  )
}

export default Testimonials