import React from 'react'
import Footer from '../../Footer/Footer'
import VisaBanner from './VisaBanner'
import VisaMain from './VisaMain'


const Visa = () => {
  return (
    <>
       
       <VisaBanner></VisaBanner>
       <VisaMain></VisaMain>
       <Footer></Footer>
    </>
  )
}

export default Visa