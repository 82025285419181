import React from 'react'
import Footer from '../../Footer/Footer'
import OnetoOneAcademicMentorshipBanner from '../onetooneacademicmentorship/OnetoOneAcademicMentorshipBanner'
import OnetoOneAcademicMentorshipMain from '../onetooneacademicmentorship/OnetoOneAcademicMentorshipMain'

const OnetoOneAcademicMentorship = () => {
    return (
        <>  
            <OnetoOneAcademicMentorshipBanner />
            <OnetoOneAcademicMentorshipMain />            
            <Footer></Footer>
        </>
    )
}
export default OnetoOneAcademicMentorship