import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import { Row, Container, Col } from 'react-bootstrap'

import ausbanner from '../../../images/ausbanner.svg'

const ToeflCoachingBanner = () => {
  return (
    <>
      <div className='innerbanner pt-4'>
        <Header></Header>
        <div className='container-fluid'>
          <Container>
            <Row className='d-flex justify-content-center align-items-center'>
              <Col md={5}>
                <img src={ausbanner} alt="ausbanner" className='animated2 infinite fadeInLeftRight'></img>
              </Col>
              <Col md={7}>
                <h2>TOEFL<span> Coaching  </span></h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default ToeflCoachingBanner