import React from 'react'
import Footer from '../../Footer/Footer'
import FullcourseAcademicSupportBanner from './FullcourseAcademicSupportBanner'
import VideotutorialsMain from './FullcourseAcademicSupportMain'
import '../innerpage.css'



const FullcourseAcademicSupport = () => {
  return (
    <>
        <FullcourseAcademicSupportBanner></FullcourseAcademicSupportBanner>
        <VideotutorialsMain></VideotutorialsMain>
        <Footer></Footer>
    </>
  )
}

export default FullcourseAcademicSupport