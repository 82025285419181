import React from 'react'
import Footer from '../Footer/Footer'

import Banner from './banner/Banner'
import Review from './review/Review'
import Aboutexp from './aboutexp/Aboutexp'
// import Faq from './faq/Faq'
import Counselling from '../counselling/Counselling'
import Whyexp from './whyexp/Whyexp'
// import StudyAbraod from './studybaroad/StudyAbraod'
function Home() {
  return (
    <>
        <Banner></Banner>
        <Review></Review>
        {/* <StudyAbraod></StudyAbraod> */}
        <Counselling></Counselling>
        <Whyexp></Whyexp>
        {/* <Faq></Faq> */}
        <Aboutexp></Aboutexp>        
        <Footer></Footer>
    </>
  )
}

export default Home