import React from 'react'
import Footer from '../../Footer/Footer'
import CanadaVisaBanner from './CanadaVisaBanner'
import CanadaVisaMain from './CanadaVisaMain'


const CanadaVisa = () => {
  return (
    <>
       
       <CanadaVisaBanner></CanadaVisaBanner>
       <CanadaVisaMain></CanadaVisaMain>
       <Footer></Footer>
    </>
  )
}

export default CanadaVisa