import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './subscribe.css'
import newsletterg from '../../../images/newsletter-bg.png'

const Subscribe = () => {

    const [email, setEmail] = useState(" ");

    const handleSubmit = event => {
        event.preventDefault(); // prevent page refresh

        // access input values here
        console.log('Email', email);

        axios.post("//accountsfinanceassignmenthelp.com/api/subscribe", { email: email })
            .then(res => {
                toast('Thank you for joining our newsletter');
            })

            .catch(function (err) {
                console.log(err);
            });
        setEmail('');
    };
    return (
        <>
            <div className='subscribe'>
                <img src={newsletterg} alt="" className='img-fluid'></img>
                <Container>
                    <Row className='inner align-items-center'>
                        <Col md={5}>

                            <h6 className='text-end'>Join Now To Get Special Offer</h6>

                        </Col>
                        <Col md={7}>

                            <div className="sub-inner">
                                <input type="text" className="form-control" placeholder="Add your Email Here" onChange={e => setEmail(e.target.value) }
                                    value={email} />
                                <button className="btn btn-info" onClick={handleSubmit} >Subscribe</button>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </>
    )
}

export default Subscribe