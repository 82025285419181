import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
import '../innerpage.css'

const OfferMain = () => {
    return (
        <>
            <Container className='studybg my-5'>
                <h4>ExpertGRAD leaders</h4>
                <p>Introducing our sharp leaders, putting efforts to make academic journey a smooth sail! </p>
            </Container>

            <Container>

                <Row>
                    <Col md={12}>
                        <div className="landingPromo">
                            <div className="flatOff col-md-5">GET 30% OFF</div>

                            <div className="flatService col-md-7">
                                <h5 className="mt-4"><b>FLAT 30% Off on all academic assistance sessions.</b></h5>

                                <p><b>Terms and Conditions:-</b></p>
                                <p>
                                    1.Valid for all technical &amp; non-technical subjects.<br />
                                    2.Minimum deadline of 4 days.<br />
                                    3.Minimum Word count of 1000 words or minimum half an hour of online tuition.</p>
                            </div>
                            <div className="promocode"><span>BMFEG30</span>
                                {/* <Link to={`/offer`} onclick="cliptoboard('BMFEG30')">Copy</Link> */}
                                <button onClick={() => navigator.clipboard.writeText('BMFEG30')}
                                >Copy</button>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default OfferMain