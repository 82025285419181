import React from 'react'
import Footer from '../../Footer/Footer'
import ContactBanner from './TypicalStudyBanner'
import ContactMain from './TypicalStudyMain'
import '../innerpage.css'



const TypicalStudy = () => {
  return (
    <>
        <ContactBanner></ContactBanner>
        <ContactMain></ContactMain>
        <Footer></Footer>
    </>
  )
}

export default TypicalStudy