import React from 'react'
import Footer from '../../Footer/Footer'
import HowtoansureBanner from './HowtoansureBanner'
import HowtoansureMain from './HowtoansureMain'
import '../innerpage.css'



const Howtoansure = () => {
  return (
    <>
        <HowtoansureBanner></HowtoansureBanner>
        <HowtoansureMain></HowtoansureMain>
        <Footer></Footer>
    </>
  )
}

export default Howtoansure