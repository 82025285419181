import '../innerpage.css'
import { Container, Row, Col } from 'react-bootstrap'
import vision from "../../../images/vision.webp"
import whatwedo from "../../../images/whatwedo.webp"
// import admisionprocess from "../../../images/admisionprocess.webp"
// import visaservice from "../../../images/visaservice.webp"
import about from "../../../images/about.svg"
import { Helmet } from 'react-helmet'
const AboutusMain = () => {


    return (
        <>
            <Helmet>
                <title>About Us - Unveiling Our Mission and Vision</title>
                <meta name='description' data-react-helmet="true" content="Get to know us better and understand our organization's mission, vision, and commitment to serving your needs. Discover the values that drive our company and the dedicated team behind our services." />
            </Helmet>
            <Container className='studybg my-5'>
                <h4>About ExpertGRAD</h4>
                <p>ExpertGRAD is the hub of education experts providing exclusive academic assistance to students
studying abroad. Whether it is graduation, post-graduation, or doctoral degree, we have a
solution if students have academic problems. We understand the value of foreign education and
the budding dream of students who want to pursue a degree in their preferred course from the
best universities abroad. However, a challenge stands at every step, and in studying abroad, the
biggest challenge is the difference between the education system. Helping students at every step
at ExpertGRAD, our professionals assist them in their academic challenges to ensure they grasp
knowledge at their own pace. </p>
            </Container>
            <Container className='my-5'>
                <Row>
                    <Col md={3}>
                        <div className='calender mt-md-5 mb-4'>
                            <img src={about} alt="" className='img-fluid'></img>
                        </div>
                    </Col>
                    <Col md={9}>
                        <h4 className='abouttitle text-left'>ExpertGRAD’s Story</h4>
                        <p>Launched in 2012 under the brand name Bizztree Consultants Pvt Ltd., ExpertGRAD has come a
long way. Bridging the gap between resources and needs, ExpertGRAD has connected
international students with the best academic mentors. Our professionals understand all the
academic challenges students face when studying abroad. Therefore, ExperGRAD has become a
bridge between academic challenges and academic assistance in this modern study era.
                        </p>
                        <p>The starting of ExpertGRAD was with the realisation of how difficult it must be to fail in
clearing any subject’s academic project because of the inability to understand a concept. A
student goes abroad with the hopes of their family attached to them, and the big “F” on the grade
card shatters all those hopes and dreams. This pain point of students became the idea of
ExpertGRAD Educational Technologies’ founder, Mr N.N. Sudhanshu, the leader behind the
success of ExpertGRAD. Since then, he has led a team of 5000+ professionals across the globe
with the same vision of never letting students fail in their academics because of any mistake. </p>

                        <p>Taking care of precisely what students want, ExpertGRAD takes pride in sharing that our team
of professionals is connected from every part of the world and is available 24/7 to guide students
via personal live sessions. Even though the foundation of ExpertGRAD took place with the
realisation of how embarrassing it is for students to fail academically. However, with time,
ExpertGRAD has grown towards betterment, and this Edtech organisation now guides Indian
and international students in easily understanding the university guidelines. Focusing on
providing easy-to-access academic assistance to students, ExpertGRAD offers educational
guidance to students across the globe. </p>

                        <p>Studying at a top university is a great opportunity for every student, but the academic challenges
that come with studying at an international institution, no one talks about it. As an international

student in a foreign land, you, as a student, are already alone. In this, when you get to write
different academic papers, each with different writing guidelines, you probably look for
assistance. As your professors may not be available round the clock, on the other hand, our
experts will help and guide you through those academic challenges. </p>
                    </Col>
                </Row>
            </Container>

            <Container className='studymain about'>
                <Row>
                    <div className='col-md-6'>
                        <h4 className='abouttitle'>ExpertGRAD’s Vision and Mission </h4>
                        <p>We at ExpertGRAD completely understand the problems students face while going through the
academic writing process and the heart-wrenching pain of failure. To ensure no other students
have to go through the pain of failing in their educational pursuits, ExpertGRAD’s professionals
cater to this challenge for students.   </p>
                        <p>With a clear vision of becoming the most preferred service provider in the domain of university
academic assistance, ExpertGRAD is passionately working to connect students with mentors and
experts from across the world to benefit students academically.  </p>

                        <p>We are standing faithfully on our motto “Together-We Grow” We believe in connecting students
with the best academic counsellors from across the globe so that they can easily understand the
types of academic projects, marking rubric, writing style, and referencing styles followed by
their institution. With this vision, we are en route towards our mission of providing top-quality
student support services in the field of international university education and academic research
assistance to international students studying in foreign lands.  </p>
                    </div>
                    <div className='col-md-6'>
                        <img src={whatwedo} alt="" className='img-fluid'></img>
                    </div>

                    <div className='col-md-6 mt-5'>
                        <img src={vision} alt="" className='img-fluid'></img>
                    </div>

                    <div className='col-md-6 mt-5'>
                        <h4 className='abouttitle'>Service Offerings</h4>
                        <p>ExpertGRAD’s foundation is based on assistance, the only mool-mantra we follow—assisting
and guiding students through their tough and challenging phases. Whether we talk about helping
a student in the initial stages of understanding the basic concepts of their assignments, assisting
them with the research process, or even explaining the difficult process of correctly citing their
sources, ExpertGRAD has always been the knight in shining armour with the sword in hand that
contains the power of excellence. With our team of professional academic advisors and mentors,
students can easily finish their educational journey without fearing about the academic projects
every other semester.
                        </p>
                        <p>Here are the offered services of ExpertGRAD</p>
                    </div>


                    

                </Row>
            </Container>

            <Container className='studymain mt-5'>
                <Row>

                    <div className='col-md-6'>
                        <div className='cp-tab'>
                            <h4 className='abouttitle'>Preparatory Crash Course</h4>
                            <p>The basic challenge students face when they study abroad is the difference between the
education system. When a student from India or any neighbouring country goes to study abroad,
the clear challenge is the type of academic projects and academic writing—with different
structures, marking rubrics, research patterns, referencing styles, and more. A student new to
these guidelines needs time to grasp complete knowledge, but it is already too late by the time
they do. This is where ExpertGRAD’s Preparatory Crash Course comes in handy. Our experts
offer a ten-day preparatory crash course to students who wish to study overseas. In this
programme, our professionals teach students about every academic guideline that is crucial for
them. From academic writing and research methodology to citing references and tricks to
proofreading their work, our experts cater to every academic challenge and train students to
ensure academic integrity and plagiarism-free work submission.
                            </p>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='cp-tab'>
                            <h4 className='abouttitle'>Full Course Module</h4>
                            <p>Covering all the academic disciplines from business, management and commerce to information
technology, nursing, and medicine, our professional mentors help students of any and every
field. When students go to study abroad, they are already going through a transition phase. They
are rebuilding a personal space and a friend circle and must perform well academically. As the
feeling of homesickness is constantly there, the pressure of failing in the first semester seems a
lot to take. At this point, students are close to giving up their studies and returning to their
respective countries. In this challenging phase, our academic mentors greatly help students.
Students fail because they are unable to understand their course and find difficulties in
prioritising their academic tasks. We offer exclusive academic assistance and support to help
students stay updated with current educational trends. By sharing their blackboard/moodle ID,
students can keep track of their next academic challenge where our experts are standing with the
best academic solution.
                            </p>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='cp-tab'>
                            <h4 className='abouttitle'>One-on-One Academic Mentorship</h4>
                            <p>Studying abroad is not easy; ask those facing accent issues every day. In a class of 60 students,
the professor will not take care of every student individually. But when a student fails to
understand the concepts taught by their professor, and they have no option but to ask the
professor again, they look for help. With no classmates of the same background or help from
their peers, students seek assistance from online tutors. This is where ExpertGRAD plays a
crucial role in helping students easily understand their complex topics even one night before their
exams. The best part about availing academic support from us is that our team consists of experts
from different nationalities. This helps students avail academic assistance from a native-speaking
tutor who gives one-on-one online assistance. The academic mentors at ExpertGRAD explain
concepts easily and teach students the correct manner of writing their assignments, along with
giving feedback to improve their assignments’ quality.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='cp-tab'>
                            <h4 className='abouttitle'>Online Assignment Bank</h4>
                            <p>Another add-on service that makes ExpertGRAD a perfect choice for students studying abroad is
our Online Assignment Bank. This is a great platform where students can find answers to every
possible academic assignment. Whether it is an essay, a research paper, a case study, or even a
dissertation on any subject/topic, students will get samples of over 100000+ assignments at
Online Assignment Bank. We acknowledge that there are educational differences and, at times,
students only need a hint to understand how to begin working on their assignments, they don’t
require complete assistance, just one sample to understand the format and get access to authentic
links. This is where ExpertGRAD’s Online Assignment Bank assists students in writing flawless
assignments with ease. The free samples help students know the correct structure of their
assignment, how they should organise their information, the authentic sources they can use for
research, and the correct format for citing their references.
                            </p>
                        </div>
                    </div>
                </Row>
            </Container>


            <Container className='studymain mb-5'>
                <h4 className='abouttitle'>ExpertGRAD’s Growth Story</h4>
                <div class="row">

                    <div className='col-md-6'>
                        <div className='studybg mt-4'>
                            <Row>
                                <Col md={4}>
                                    <div className='calender' style={{ color: "#57BEDF" }}>
                                        2016
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <p className="text-start">The company grew by leaps and bounds. In 2016, we were a family of 50+ academic enthusiasts and soon marked a niche in the market.  </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='studybg mt-4'>
                            <Row>
                                <Col md={4}>
                                    <div className='calender' style={{ color: "#F9C015" }}>
                                        2017
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <p className="text-start">ExpertGRAD was established as an EdTech company in Bangalore by the name Bizztree Consultants Pvt. Ltd., transforming digital solutions for the education industry. </p>
                                </Col>
                            </Row>
                        </div>
                    </div>


                    <div className='col-md-6'>
                        <div className='studybg mt-4'>
                            <Row>
                                <Col md={4}>
                                    <div className='calender' style={{ color: "#3FA474" }}>
                                        2020
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <p className="text-start">ExpertGRAD sets a benchmark for excellence in offering outstanding guidance to students via one-to-one custom online classes.  </p>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='studybg mt-4'>
                            <Row>
                                <Col md={4}>
                                    <div className='calender'>
                                        2022
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <p className="text-start">For more exposure to the domain, we partnered with brands like BookMyForex and opened new horizons in the study abroad section. Currently, we are leading the path of visa services, career counselling, admissions and more for the students who need our assistance at each stage of their educational journey.  </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    )
}

export default AboutusMain