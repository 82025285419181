import React from 'react'
import Footer from '../../Footer/Footer'
import AustraliaVisaBanner from './AustraliaVisaBanner'
import AustraliaVisaMain from './AustraliaVisaMain'


const AustraliaVisa = () => {
  return (
    <>       
       <AustraliaVisaBanner></AustraliaVisaBanner>
       <AustraliaVisaMain></AustraliaVisaMain>
       <Footer></Footer>
    </>
  )
}

export default AustraliaVisa