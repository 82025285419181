import React from 'react'
import Header from '../../Header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../innerpage.css'
import {Row, Container, Col} from 'react-bootstrap'

import visaguideline from '../../../images/visaguideline.png'

const SmainGuidelineBanner = () => {
  return (
    <>
        <div className='innerbanner pt-4'>
                <Header></Header>
                <div className='container-fluid'>
                <Container>
                <Row className='d-flex justify-content-center align-items-center'>                    
                    <Col md={5}>
                        <img src={visaguideline} alt="visaguideline" className='animated2 infinite fadeInLeftRight'></img>                        
                    </Col>
                    <Col md={7}>
                            <h2>Student VISA  <span>Guidelines</span></h2>
                            <p>On-Board to hear “Welcome Abroad” </p>
                    </Col>
                </Row>
            </Container>
                </div>
            </div>
    </>
  )
}

export default SmainGuidelineBanner