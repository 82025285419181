import React from 'react'
import Footer from '../../Footer/Footer'
import UsaBanner from './UsaBanner'
import UsaStudy from './UsaStudy'
import '../innerpage.css'



const StudyinUsa = () => {
  return (
    <>
        <UsaBanner></UsaBanner>
        <UsaStudy></UsaStudy>
        <Footer></Footer>
    </>
  )
}

export default StudyinUsa