import React from 'react'
import Footer from '../../Footer/Footer'
import UkVisaBanner from './UkVisaBanner'
import UkVisaMain from './UkVisaMain'


const UkVisa = () => {
  return (
    <>       
       <UkVisaBanner></UkVisaBanner>
       <UkVisaMain></UkVisaMain>
       <Footer></Footer>
    </>
  )
}

export default UkVisa